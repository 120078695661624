import {CheckOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Form, Input, Tooltip} from 'antd';
import React, {useEffect, useRef, useState} from 'react';

const EditableField = ({
  label,
  value,
  onChange,
  hasChanged,
  placeholder,
  style = {},
  size,
  rules = [],
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editing) {
      form.setFieldsValue({name: value});
      inputRef.current.focus();
    }
  }, [editing]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        setEditing(false);
        onChange(values.name);
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setEditing(false);
    form.resetFields();
  };

  return (
    <>
      {label ? <h3>{label}</h3> : null}
      {editing ? (
        <Form
          form={form}
          className="flex-row"
          style={{
            marginBottom: 0,
            alignItems: 'center',
            display: 'inline-block',
          }}
        >
          <Form.Item name="name" rules={rules} style={{marginBottom: 0}}>
            <Input
              ref={inputRef}
              style={{width: style.width ?? 300, display: 'inline-block'}}
              size={size ?? 'default'}
              onPressEnter={handleSave}
              onBlur={handleSave}
            />
          </Form.Item>
          <Button
            size={size ?? 'default'}
            type="primary"
            icon={<CheckOutlined />}
            onClick={handleSave}
          />
          <Button
            size={size ?? 'default'}
            type="default"
            icon={<CloseOutlined style={{color: 'black'}} />}
            onClick={handleCancel}
          />
        </Form>
      ) : (
        <>
          <Tooltip title="Click to edit">
            <span
              style={
                style ?? {
                  backgroundColor: hasChanged ? 'orange' : 'transparent',
                  padding: 4,
                }
              }
              onClick={() => setEditing(true)}
            >
              {value ?? placeholder ?? 'Click to edit'}
              <EditOutlined />
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default EditableField;
