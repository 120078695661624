import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import {validMetricsForColumnType, validTypesForFilter} from 'consts';
import React from 'react';

const MetricForm = ({columns, metric, setMetric}) => {
  const parsedColumns = columns
    .map((column) => ({
      value: column.column_name,
      label: `${column.column_name} (${column.data_type})`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="flex-column" style={{alignItems: 'stretch'}}>
      <SelectOptions
        options={parsedColumns}
        selectedOption={metric.source_column}
        changeSelection={(value) => setMetric(value, 'source_column')}
        placeholder="Select a source column"
      />
      {metric.source_column && (
        <>
          <InputField
            label="Column Name"
            required={true}
            onChange={(e) => setMetric(e.target.value, 'alias')}
            value={metric.alias ?? metric.source_column}
          />
          <SelectOptions
            options={Object.keys(validMetricsForColumnType).map((option) => {
              return {
                value: option,
                label: option,
                disabled: !validMetricsForColumnType[option].includes(
                  metric.source_data_type
                ),
              };
            })}
            selectedOption={metric.metric}
            changeSelection={(value) => setMetric(value, 'metric')}
            label="Metric"
            required={true}
          />
          <SelectOptions
            options={Object.keys(validTypesForFilter).map((option) => {
              return {
                value: option,
                label: option,
                disabled: !validTypesForFilter[option].includes(
                  metric.data_type
                ),
              };
            })}
            selectedOption={metric.filter_type}
            changeSelection={(value) => setMetric(value, 'filter_type')}
            label="Filter Type"
            required={true}
          />
          <InputField
            label="Description"
            onChange={(e) => setMetric(e.target.value, 'description')}
            value={metric.description}
          />
        </>
      )}
    </div>
  );
};

export default MetricForm;
