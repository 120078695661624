import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const tableEditorSlice = createSlice({
  name: 'tableEditor',
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
export const {} = tableEditorSlice.actions;

export default tableEditorSlice.reducer;
