import {SearchOutlined} from '@ant-design/icons';
import {Button, Checkbox, Input, Table} from 'antd';
import {useFetchStaffProvisioningStatusQuery} from 'api/apiSlice';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {useLocation, useNavigate} from 'react-router-dom';

const SYSTEMS = {
  'Illuminate MA': 'illuminate_ma',
  'Illuminate NJ': 'illuminate_nj',
  'Illuminate NY': 'illuminate_ny',
  'Power School MA': 'power_school_ma',
  'Power School NJ': 'power_school_nj',
  'Power School NY': 'power_school_ny',
  'PowerTeacher MA': 'powerteacher_ma',
  'PowerTeacher NJ': 'powerteacher_nj',
  'PowerTeacher NY': 'powerteacher_ny',
  Tableau: 'tableau',
  'School Messenger': 'school_messenger',
};

const StaffRolesTable = ({siteId}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {data} = useFetchStaffProvisioningStatusQuery(siteId);

  const [dataSource, setDataSource] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [hideSystemsWithNoAccess, setHideSystemsWithNoAccess] = useState(
    siteId === 'all' ? false : true
  );
  const [pagination, setPagination] = useState({
    pageSize: 50,
    position: ['bottomRight', 'topRight'],
  });

  useEffect(() => {
    // parse the query string
    const filters = {};
    const sort = {};
    if (location.search) {
      let params = location.search.slice(1).split('&');
      for (const p of params) {
        let [key, value] = p.split('=');
        if (key.startsWith('filter_')) {
          key = key.slice(7);
          if (!filters[key]) {
            filters[key] = [];
          }
          filters[key].push(value);
        } else if (key === 'sort') {
          [key, value] = value.split(':');
          sort.columnKey = key;
          sort.order = value;
        }
      }
    }
    setFilteredInfo(filters);
    setSortedInfo(sort);
  }, [location.search]);

  useEffect(() => {
    if (data) {
      setDataSource(setupTableData(data));
    }
  }, [data]);

  const searchInput = useRef();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <div className="flex-row">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Filter
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Clear
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const setSearchParams = () => {
    let search = '?';

    if (sortedInfo) {
      search +=
        'sortColumn=' + sortedInfo.field + '&sortOrder=' + sortedInfo.order;
    }

    for (const field in filteredInfo) {
      let f = filteredInfo[field];

      if (f) {
        for (const entry in f) {
          search += `&filter_${field}=${f[entry]}`;
        }
      }
    }

    navigate(search);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
    setSearchParams();
  };

  const setupTableData = (dataSource) => {
    let newDataTable = []; // Loop over the users dataSource, and for each user loop over the sites.

    for (const i in dataSource) {
      let user = dataSource[i]; // Loop over all the sites the user has access to.
      // We'll add a row for each site.

      for (const j in user.sites) {
        let all_systems = {};
        let site = user.sites[j];
        let siteSystems = site;

        for (const system in SYSTEMS) {
          let system_status_display = '';
          let sysname = SYSTEMS[system];
          let is_primary_site = j === user.site_id;
          const STATUS_LOOKUP_TABLE_SCHOOL = {
            // [HAS ACCESS][NEEDS ACCESS]:
            Yes: {
              // HAS_ACCESS=True
              Yes: 'Provisioned',
              No: 'No access required',
              '?': 'No access required',
            },
            No: {
              // HAS_ACCESS=True
              Yes: 'In Progress',
              No: 'No access required',
              '?': 'No access required',
            },
          };
          const STATUS_LOOKUP_TABLE_ALL_VIEW = {
            // [HAS ACCESS][NEEDS ACCESS]:
            Yes: {
              // HAS_ACCESS=True
              Yes: is_primary_site
                ? 'Has Access at Home School'
                : 'Has Access at Additonal School',
              No: "Shouldn't have access",
              '?': 'Has Account, Needs Investigation',
            },
            No: {
              // HAS_ACCESS=True
              Yes: is_primary_site
                ? 'Needs Account'
                : 'Not Required (not primary site)',
              No: is_primary_site
                ? 'No access required'
                : 'No Account, not required',
              '?': 'No Account, Needs Investigation',
            },
          }; // Choose a lookup table based on whether this is the all view or not.

          let status_lookup_table;

          if (siteId !== 'all') {
            status_lookup_table = STATUS_LOOKUP_TABLE_SCHOOL;
          } else {
            // This is the all sites view.
            status_lookup_table = STATUS_LOOKUP_TABLE_ALL_VIEW;
          }

          let has_access;
          let needs_access;

          if (!siteSystems[sysname]) {
            has_access = 'No';
            needs_access = user.role ? 'No' : '?';
          } else {
            has_access = siteSystems[sysname].has_access ? 'Yes' : 'No';
            needs_access = siteSystems[sysname].needs_access
              ? 'Yes'
              : user.role
                ? 'No'
                : '?';
          }

          system_status_display = status_lookup_table[has_access][needs_access];
          all_systems[sysname] = system_status_display;
        }

        let newRow = {
          key: user.employee_id + '_' + j,
          employee_name: user.employee_name,
          employee_id: user.employee_id,
          title: user.title,
          email: user.email || '',
          role: user.role || '',
          site_id: user.site_id,
          row_site_id: j,
          school_abbr: user.school_abbr + ' (' + user.site_id + ')',
          ...all_systems,
        };
        newDataTable.push(newRow);
      }
    }

    return newDataTable;
  };

  const getProvisionStatusesForFilter = () => {
    if (siteId === 'all') {
      return [
        {
          text: 'Has Access at Home School',
          value: 'Has Access at Home School',
        },
        {
          text: 'Has Access at Additonal School',
          value: 'Has Access at Additonal School',
        },
        {
          text: "Shouldn't have access",
          value: "Shouldn't have access",
        },
        {
          text: 'Has Account, Needs Investigation',
          value: 'Has Account, Needs Investigation',
        },
        {
          text: 'Needs Account',
          value: 'Needs Account',
        },
        {
          text: 'Not Required (not primary site)',
          value: 'Not Required (not primary site)',
        },
        {
          text: 'No Access Required',
          value: 'Not Required (not primary site)',
        },
        {
          text: 'No Account, not required',
          value: 'No Account, not required',
        },
        {
          text: 'No Account, Needs Investigation',
          value: 'No Account, Needs Investigation',
        },
      ];
    } else {
      return [
        {
          text: 'No access required',
          value: 'No access required',
        },
        {
          text: 'In Progress',
          value: 'In Progress',
        },
        {
          text: 'Provisioned',
          value: 'Provisioned',
        },
      ];
    }
  };

  const getHiddenSystems = () => {
    let hiddenSystems = [];

    for (const system in SYSTEMS) {
      let sysname = SYSTEMS[system];
      let colempty = true;

      for (const i in dataSource) {
        let user = dataSource[i];

        if (user[sysname] !== 'No access required') {
          colempty = false;
          continue;
        }
      }

      if (colempty) {
        hiddenSystems.push(system);
      }
    }

    return hiddenSystems;
  };

  const getTotalStaffCount = () => {
    if (!dataSource) {
      return 0;
    } else {
      let user_ids = dataSource.map((user) => user.employee_id);
      let unique_user_ids = [...new Set(user_ids)];
      return unique_user_ids.length;
    }
  };

  const getSystemColumns = () => {
    let ret = [];

    for (const system in SYSTEMS) {
      let sysname = SYSTEMS[system]; // If we only want to display non-empty columns:

      if (hideSystemsWithNoAccess) {
        // Let's figure out whether this system has any access requirements.
        let colempty = true;

        for (const i in dataSource) {
          let user = dataSource[i];

          if (user[sysname] !== 'No access required') {
            colempty = false;
          }
        }

        if (colempty) {
          // Continue to the next system.
          continue;
        }
      }

      let TEXT_TO_COLOR_MAP;

      if (siteId === 'all') {
        TEXT_TO_COLOR_MAP = {
          'No access required': '#00da63',
          'No Account, not required': '#00da63',
          'Needs Account': '#ea9999',
          'Not Required (not primary site)': '#00da63',
          'No Account, Needs Investigation': '#a64d79',
          'Has Account, Needs Investigation': '#8e7cc3',
          'Has Access at Home School': '#00da63',
          'Has Access at Additonal School': '#b6ff80',
          "Shouldn't have access": '#f4cccc',
        };
      } else {
        TEXT_TO_COLOR_MAP = {
          'In Progress': '#b6ff80',
          Provisioned: '#00da63',
        };
      }

      let new_col = {
        title: system,
        dataIndex: sysname,
        key: sysname,
        width: 150,
        sorter: (a, b) => a[sysname].localeCompare(b[sysname]),
        filters: getProvisionStatusesForFilter(),
        onFilter: (value, record) => record[sysname].indexOf(value) === 0,
        onCell: (value, record) => {
          let bgcolor = TEXT_TO_COLOR_MAP[value[sysname]];
          return {
            style: {
              background: bgcolor,
              color: value[sysname] === 'No access required' ? '#D3D3D3' : '',
            },
          };
        },
      };
      ret.push(new_col);
    }

    return ret;
  };

  const getColumnsForTable = () => {
    let columns = [
      {
        title: 'Name',
        dataIndex: 'employee_name',
        key: 'employee_name',
        width: 150,
        sorter: (a, b) => a.employee_name.localeCompare(b.employee_name),
        ...getColumnSearchProps('employee_name'),
        fixed: 'left',
      },
      {
        title: 'Employee Id',
        dataIndex: 'employee_id',
        key: 'employee_id',
        width: 150,
        sorter: (a, b) => a.employee_id.localeCompare(b.employee_id),
        ...getColumnSearchProps('employee_id'),
        fixed: 'left',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        sorter: (a, b) => a.email.localeCompare(b.email),
        ...getColumnSearchProps('email'),
        fixed: 'left',
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 150,
        sorter: (a, b) => a.title.localeCompare(b.title),
        ...getColumnSearchProps('title'),
        fixed: 'left',
      },
    ]; // Role columns only for the itsupport view.

    if (siteId === 'all') {
      columns.push({
        title: 'Home Site',
        dataIndex: 'school_abbr',
        key: 'school_abbr',
        width: 150,
        sorter: (a, b) => a.school_abbr.localeCompare(b.school_abbr),
        ...getColumnSearchProps('school_abbr'),
      });
      columns.push({
        title: 'Site',
        dataIndex: 'row_site_id',
        key: 'row_site_id',
        width: 150,
        sorter: (a, b) => a.row_site_id.localeCompare(b.row_site_id),
        ...getColumnSearchProps('row_site_id'),
      });
      columns.push({
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        width: 150,
        sorter: (a, b) => a.role.localeCompare(b.role),
        ...getColumnSearchProps('role'),
      });
    }
    columns.push(...getSystemColumns());
    return columns;
  };

  return (
    <div>
      {dataSource && (
        <p>
          <strong>Total staff:&nbsp;</strong>
          {getTotalStaffCount()}
        </p>
      )}

      {siteId !== 'all' && (
        <div>
          <Checkbox
            style={{
              marginLeft: '0',
            }}
            checked={hideSystemsWithNoAccess}
            onChange={(e) =>
              setHideSystemsWithNoAccess(!hideSystemsWithNoAccess)
            }
          >
            Hide systems with no access requirements
          </Checkbox>
          {hideSystemsWithNoAccess && (
            <div>
              (Hidden systems:{' '}
              {getHiddenSystems().map((system) => {
                return system + ', ';
              })}
              )
            </div>
          )}
        </div>
      )}

      <Table
        dataSource={dataSource}
        columns={getColumnsForTable()}
        key={dataSource}
        pagination={pagination}
        size="small"
        loading={dataSource === null}
        onChange={handleTableChange}
        bordered
        title={(currentPageData) => {
          return <div>Currently displayed: {currentPageData.length}</div>;
        }}
        scroll={{
          y: '80vh',
        }}
      />
    </div>
  );
};

export default StaffRolesTable;
