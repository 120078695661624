import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum';
import App from 'App';
import 'App.less';
import Auth from 'Auth';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import {store} from 'store/store';

const user = Auth.getUser();

// if localhost, don't send to datadog
if (window.location.hostname !== 'localhost') {
  console.log('initializing datadog', window.location.hostname);
  // initialize datadogRum for session tracking
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'track',
    env: process.env.REACT_APP_ENVIRONMENT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    forwardErrorsToLogs: true,
    version: process.env.REACT_APP_VERSION,
  });
  // set the user information
  datadogRum.setUser({
    id: user?.sub,
    email: user?.email,
  });

  // initialize datadogLogs for error tracking
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    // forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
    env: process.env.REACT_APP_ENVIRONMENT,
    service: 'track',
    version: process.env.REACT_APP_VERSION,
    trackSessionAcrossSubdomains: true,
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  // <ErrorBoundary
  //   fallback={'An error has occurred'}
  //   onError={(error) => {
  //     captureException(error);
  //   }}
  // >
  <Provider store={store}>
    <App />
  </Provider>
  // </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// expose store when run in Cypress
if (window.Cypress) {
  console.log('Attaching store to window');
  window.store = store;
}

// get the datadog session id and save it to local storage
console.log(
  'setting datadog session id',
  datadogRum.getInternalContext()?.session_id
);
Auth.setLocalItems({
  datadog_session_id: datadogRum.getInternalContext()?.session_id,
});
