import {SortAscendingOutlined} from '@ant-design/icons';
import {ResponsiveBar} from '@nivo/bar';
import {Divider, Tooltip} from 'antd';
import Student from 'components/esoy/Student';
import React, {useEffect, useState} from 'react';
import {Droppable} from 'react-beautiful-dnd';
import styled from 'styled-components';

const barColors = {
  IEP: '#8BFFFB',
  ELL: '#EABBF7',
  504: '#FDB0DE',
  Female: '#C0F7A5',
  Male: '#F5F9BB',
  'Non-Binary': '#FBB269',
  '-': 'hsl(0, 0%, 100%)',
};

const getColor = (bar) => barColors[bar.id];

export function StatusBarGraph({data, keys}) {
  return (
    <ResponsiveBar
      data={data}
      width={110}
      keys={keys}
      padding={0.3}
      layout="horizontal"
      valueScale={{type: 'linear'}}
      indexScale={{type: 'band', round: true}}
      colors={getColor}
      borderColor={{from: 'color', modifiers: [['darker', 0.6]]}}
      borderWidth={1}
      enableLabel={false}
      animate
      tooltip={({id, value}) => (
        <strong>
          {value} {id}
        </strong>
      )}
      motionStiffness={90}
      motionDamping={15}
    />
  );
}

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
`;

const Title = styled.h3`
  padding: 8px;
  padding-bottom: 0px;
`;

const TeacherName = styled.div`
  padding-left: 8px;
  margin-bottom: 6px;
  padding-bottom: 4px;
  min-height: 24px;
`;

const ListCount = styled.div`
  padding-left: 8px;
  margin-bottom: 6px;
`;

const TagContainer = styled.div`
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  height: 24px;
  text-align: right;
`;

const TagsContainer = styled.div`
  padding: 4px 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid #d6d3d3;
`;

const StudentList = styled.div`
  padding: 8px;
  transition: background-color 0.1s ease;
  background-color: ${(props) => (props.isDraggingOver ? '#e6f5fa' : 'white')};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 100px;
  overflow-y: auto;
  min-width: 200px;
`;

const SortIcon = styled.div`
  padding-right: 5px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#1890ff' : '#d6d3d3')};
  &:hover {
    color: ${(props) => (props.selected ? '#1890ff' : '#C7C7C7')};
  }
`;

function Tags({handleSortStudents, sortBy, students}) {
  const graphData = {
    fCount: students.filter((s) => s.gender === 'F').length,
    mCount: students.filter((s) => s.gender === 'M').length,
    xCount: 0,
    iepCount: students.filter((e) => e.has_iep).length,
    ellCount: students.filter((e) => e.has_ell).length,
    has504Count: students.filter((e) => e.has_504).length,
  };

  const graphFields = [
    {
      name: 'gender',
      title: 'Gender',
      keys: ['Female', 'Male', 'Non-binary'],
      data: [
        {
          field: 'Gender',
          Female: graphData.fCount,
          Male: graphData.mCount,
          'Non-Binary': students.length - graphData.fCount - graphData.mCount,
        },
      ],
    },
    {
      name: 'has_ell',
      title: 'ELL',
      keys: ['ELL', '-'],
      data: [
        {
          field: 'ELL',
          ELL: graphData.ellCount,
          '-': students.length - graphData.ellCount,
        },
      ],
    },
    {
      name: 'has_iep',
      title: 'IEP',
      keys: ['IEP', '-'],
      data: [
        {
          field: 'IEP',
          IEP: graphData.iepCount,
          '-': students.length - graphData.iepCount,
        },
      ],
    },
    {
      name: 'has_504',
      title: '504',
      keys: ['504', '-'],
      data: [
        {
          field: '504',
          504: graphData.has504Count,
          '-': students.length - graphData.has504Count,
        },
      ],
    },
  ];

  return (
    <TagsContainer>
      {graphFields.map((field) => (
        <TagContainer key={field.name}>
          <Tooltip
            placement="left"
            title={
              sortBy === field.name ? `Remove sort` : `Sort by ${field.title}`
            }
          >
            <SortIcon
              selected={sortBy === field.name}
              onClick={() => handleSortStudents(field.name)}
            >
              <SortAscendingOutlined />
            </SortIcon>
          </Tooltip>
          <p>{field.title}:</p>
          <StatusBarGraph data={field.data} keys={field.keys} />
        </TagContainer>
      ))}
    </TagsContainer>
  );
}

const AssignHomeroomsColumn = ({
  students,
  handleSortStudents,
  selectedStudentIds,
  disabled,
  handleToggleSelect,
  column,
}) => {
  const [teacherNameDisplay, setTeacherNameDisplay] = useState();
  const [sortBy, setSortBy] = useState();

  useEffect(() => {
    if (column.title !== 'Unassigned Students') {
      setTeacherNameDisplay(
        column.teacher_name
          ? 'Teacher: ' + column.teacher_name
          : '(No teacher assigned)'
      );
    }
  }, []);

  const sortStudentsByGender = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (a.gender > b.gender) return 1;
      if (a.gender < b.gender) return -1;
      return 0;
    });
    return sortedStudents;
  };

  const sortStudentsByName = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (a.student_name > b.student_name) return 1;
      return -1;
    });
    return sortedStudents;
  };

  const sortStudentsByFlag = (flag) => {
    const sortedStudents = [...students].sort((a, b) => {
      if (a[flag] && !b[flag]) return -1;
      return 1;
    });
    return sortedStudents;
  };

  const onSortStudents = (newSortBy) => {
    let sortedStudents;

    if (newSortBy === sortBy) {
      newSortBy = 'name';
    }

    switch (newSortBy) {
      case 'name':
        sortedStudents = sortStudentsByName();
        break;

      case 'gender':
        sortedStudents = sortStudentsByGender();
        break;

      case 'has_ell':
      case 'has_iep':
      case 'has_504':
        sortedStudents = sortStudentsByFlag(newSortBy);
        break;

      default:
        sortedStudents = students;
        break;
    }

    handleSortStudents(sortedStudents, newSortBy);
    setSortBy(newSortBy);
  };

  return (
    <Container>
      <Title>{column.title}</Title>

      <TeacherName>{teacherNameDisplay}</TeacherName>

      <ListCount>{students?.length} Students</ListCount>
      <Divider
        style={{
          margin: '0',
          border: '0.5px solid #d6d3d3',
        }}
      />

      {students?.length ? (
        <Tags
          sortBy={sortBy}
          handleSortStudents={onSortStudents}
          students={students}
        />
      ) : null}
      <Droppable droppableId={column.homeroom_id} isDropDisabled={disabled}>
        {(provided, snapshot) => {
          return (
            <StudentList
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              {...provided.droppableProps}
            >
              {students?.map((student, index) => {
                const isSelected = selectedStudentIds.includes(
                  student.student_id
                );
                return (
                  <Student
                    key={student.student_id + index}
                    student={student}
                    index={index}
                    handleToggleSelect={handleToggleSelect}
                    isSelected={isSelected}
                    selectionCount={selectedStudentIds.length}
                    disabled={disabled}
                  />
                );
              })}
              {provided.placeholder}
            </StudentList>
          );
        }}
      </Droppable>
    </Container>
  );
};

export default AssignHomeroomsColumn;
