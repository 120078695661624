import {SearchOutlined} from '@ant-design/icons';
import {ResponsiveLine} from '@nivo/line';
import {Button, Input, Spin, Table} from 'antd';
import {
  useFetchErrorRateBySchoolQuery,
  useFetchErrorRateSnapshotQuery,
} from 'api/auditsSlice';
import React, {useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

// Our line bar component.
function DateSchoolLineBar({data}) {
  return (
    <>
      {data?.length ? (
        <ResponsiveLine
          data={data}
          margin={{top: 50, right: 110, bottom: 80, left: 60}}
          xScale={{type: 'point'}}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="monotoneX"
          colors={{scheme: 'set3'}}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -35,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '# errors',
            legendOffset: -50,
            legendPosition: 'middle',
          }}
          pointSize={10}
          pointColor={{theme: 'background'}}
          pointBorderWidth={2}
          pointBorderColor={{from: 'serieColor'}}
          tooltip={(p) => (
            <>
              <strong>{p.point.serieId}</strong>: {p.point.data.y}
            </>
          )}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 15,
              itemOpacity: 0.75,
              symbolSize: 10,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      ) : null}
    </>
  );
}

const AuditsOverviewTable = ({chosenRegion}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [dataForTable, setDataForTable] = useState([]);
  const [filteredGraphData, setFilteredGraphData] = useState([]);
  const [graphHeight, setGraphHeight] = useState(0);

  const {data: graphData, isLoading: snapshotLoading} =
    useFetchErrorRateSnapshotQuery();

  const {data, isLoading: schoolLoading} = useFetchErrorRateBySchoolQuery();

  let searchInput = useRef();

  useEffect(() => {
    getDataForGraph();
    getDataForTable();
  }, [graphData, data, chosenRegion]);

  useEffect(() => {
    if (filteredGraphData.length) {
      setGraphHeight(Math.max(400, filteredGraphData.length * 20));
    } else {
      setGraphHeight(0);
    }
  }, [filteredGraphData]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{padding: 8}}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <div className="flex-row">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{width: 90}}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getDataForTable = () => {
    if (!data) {
      return [];
    }
    // Filter the school audit data to only the chosen region.
    let filteredData = [...data.errorRate];

    if (chosenRegion !== 'All') {
      filteredData = filteredData.filter((e) => e.region === chosenRegion);
    }
    setDataForTable(filteredData);
  };

  const getDataForGraph = () => {
    if (!graphData || !data) {
      return [];
    }
    // Filter the data to only include things in this region.
    // Filter the school audit data to only the chosen region.
    let schools = data.errorRate;

    if (chosenRegion !== 'All') {
      schools = schools.filter((e) => e.region === chosenRegion);
    }
    const filteredData = [...graphData].filter((d) => {
      return schools.map((e) => e.school).includes(d.id);
    });
    setFilteredGraphData(filteredData);
  };

  const columns = [
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      sorter: (a, b) => a.school.localeCompare(b.school),
      ...getColumnSearchProps('school'),
      render: (text) => (
        <Link to={`/audits_school_summary/${text}`}>{text}</Link>
      ),
    },
    {
      title: '# Errors',
      dataIndex: 'errors',
      key: 'errors',
      align: 'right',
      sorter: (a, b) => a.errors - b.errors,
    },
    {
      title: '# Students with >=1 Error',
      dataIndex: 'student_w_error>=1',
      key: 'student_w_error>=1',
      align: 'right',
      sorter: (a, b) => a['student_w_error>=1'] - b['student_w_error>=1'],
    },
    {
      title: 'Total # Audited Students',
      dataIndex: 'total#students',
      key: 'total_students',
      align: 'right',
      sorter: (a, b) => a['total#students'] - b['total#students'],
    },
    {
      title: '% Students w >=1 Errors',
      dataIndex: '%student_w_error>=1',
      key: 'percent_student_w_error',
      align: 'right',
      sorter: (a, b) => a['%student_w_error>=1'] - b['%student_w_error>=1'],
      render: (text) => <> {text}% </>,
    },
  ];

  return (
    <>
      <Spin spinning={snapshotLoading}>
        <div
          style={{
            height: graphHeight,
          }}
        >
          {graphData && <DateSchoolLineBar data={filteredGraphData} />}
        </div>
      </Spin>

      <Table
        dataSource={dataForTable}
        columns={columns}
        size="small"
        loading={schoolLoading}
        rowKey="school"
      />
    </>
  );
};

export default AuditsOverviewTable;
