import {getDisplayGrade} from 'Utils';
import {Alert, Card, Checkbox, Spin, message} from 'antd';
import {
  useFetchCourseSelectionQuery,
  useFetchSchoolGradespanQuery,
  useSetSchoolCourseMutation,
} from 'api/esoySlice';
import TaskComplete from 'components/TaskComplete';
import React, {useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
`;

const CoursesSelect = ({siteId}) => {
  const [completed, setCompleted] = useState(false);

  const {data: courseSelectionData, isLoading} = useFetchCourseSelectionQuery(
    siteId,
    {
      skip: !siteId,
    }
  );
  const {data: schoolGradespanData, isLoading: gradespanLoading} =
    useFetchSchoolGradespanQuery(siteId, {
      skip: !siteId,
    });
  const [setSchoolCourse] = useSetSchoolCourseMutation();

  const getCoursesForGrade = (grade) => {
    if (!courseSelectionData) return;
    if (grade === 100) {
      return courseSelectionData.filter((e) => e.crossgrade);
    }
    return courseSelectionData.filter(
      (e) => e.grade === grade.toString() && !e.crossgrade
    );
  };

  const onToggleCourseSelect = (selection) => {
    let course = [
      {
        site_id: selection.site_id,
        grade: selection.grade,
        course_name: selection.course_name,
        course_number: selection.course_number,
        selected: !selection.selected,
        crossgrade: selection.crossgrade,
      },
    ];

    // Then notify the server of this change.
    setSchoolCourse({siteId, course})
      .unwrap()
      .then(() => {
        message.success('Course selection updated successfully');
      })
      .catch(() => {
        message.error('Error updating course selection');
      });
  };

  return (
    <div>
      <Alert
        message="Offer Courses (MS Only)"
        description={
          <div>
            <ol>
              <li>
                For each grade level, check the boxes of the courses that your
                school will offer for the following school year
              </li>
              <ul>
                <li>
                  All grade levels will have a homeroom/advisory course by
                  default which cannot be deselected
                </li>
              </ul>
              <li>
                If you need a new course that’s not on this list, please reach
                out to your RSO/RSI for approval.
              </li>
              <ul>
                <li>
                  Once it’s been approved, reach out to{' '}
                  <a href="mailto:ITSupport@uncommonschools.org">
                    ITSupport@uncommonschools.org
                  </a>{' '}
                  for creation
                </li>
              </ul>
            </ol>
            <p>
              Click{' '}
              <a
                href="https://uncommonschoolssupport.zendesk.com/hc/en-us/articles/4404678544141"
                target="_"
              >
                here
              </a>{' '}
              to view our kBase article with more details, screenshots, and
              examples.
            </p>
          </div>
        }
        type="success"
      />

      <Container>
        <h3>What courses will you offer?</h3>

        <TaskComplete
          key={JSON.stringify(courseSelectionData)}
          siteId={siteId}
          taskName="offer_courses"
          setCompletionStatus={setCompleted}
          isTaskCompletable={() => true}
          taskIncompleteableMessage={<></>}
          taskCompleteableMessage={<>Can mark task as complete.</>}
        />
      </Container>

      <Spin spinning={isLoading || gradespanLoading}>
        {schoolGradespanData?.gradespan.length ? (
          schoolGradespanData?.gradespan.map((grade) => {
            return (
              <Card
                key={grade}
                title={getDisplayGrade(grade)}
                style={{
                  width: '100%',
                  marginBottom: 40,
                }}
              >
                {getCoursesForGrade(grade) ? (
                  getCoursesForGrade(grade).map((course, i) => {
                    return (
                      <div key={i}>
                        <Checkbox
                          checked={course.selected}
                          onChange={(e) => onToggleCourseSelect(course)}
                          disabled={course.is_homeroom || completed}
                        >
                          {course.course_name}
                        </Checkbox>
                      </div>
                    );
                  })
                ) : (
                  <p>No courses found for this grade.</p>
                )}
              </Card>
            );
          })
        ) : (
          <p>No grades found for this school.</p>
        )}
      </Spin>
    </div>
  );
};

export default CoursesSelect;
