import {
  ClockCircleTwoTone,
  DatabaseTwoTone,
  DiffTwoTone,
  IdcardTwoTone,
  ScheduleTwoTone,
  SlidersTwoTone,
} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Card, Divider} from 'antd';
import {HIDDEN_TABS, TRACK_LITE} from 'consts';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const LinkCard = styled(Card)`
  margin: 20px 20px 0 0;
  width: 400px;
  display: inline-block;
  cursor: pointer;
  :hover {
    background-color: #e6f7ff;
  }
`;

const HomePage = () => {
  if (TRACK_LITE) {
    return (
      <div>
        <h1>Welcome to TRACK</h1>
        <p>TRACK is a tool that helps you manage your school data.</p>
        {!Auth.isLoggedIn() && (
          <p>
            To get started, please{' '}
            <Button type="primary" href="/login">
              Login
            </Button>
          </p>
        )}
      </div>
    );
  }
  const links = [
    {
      title: 'Audits',
      link: '/audits/',
      icon: <DiffTwoTone />,
      description: 'View and manage audits.',
    },
    ...(Auth.permissions.access_to_esoy && !HIDDEN_TABS.includes('school_setup')
      ? [
          {
            title: 'E/SOY School Setup',
            link: '/school_setup/',
            icon: <ScheduleTwoTone />,
            description: 'Set up your school for the next school year.',
          },
        ]
      : []),
    ...(Auth.permissions.access_to_staff_account_status &&
    !HIDDEN_TABS.includes('staff_roles')
      ? [
          {
            title: 'Staff Accounts',
            link: '/staff_roles/',
            icon: <IdcardTwoTone />,
            description: 'Manage staff accounts.',
          },
        ]
      : []),
    ...(Auth.permissions.access_to_data_reports &&
    !HIDDEN_TABS.includes('data_report')
      ? [
          {
            title: 'Data Reports',
            link: '/data_report/',
            icon: <SlidersTwoTone />,
            description: 'View customized data reports.',
          },
        ]
      : []),
    ...(Auth.permissions.access_to_hs_schedule &&
    !HIDDEN_TABS.includes('hs_schedule')
      ? [
          {
            title: 'HS Schedule',
            link: '/hs_schedule/',
            icon: <ClockCircleTwoTone />,
            description: "Set up your school's HS schedule.",
          },
        ]
      : []),
    ...(Auth.permissions.access_to_source_manager &&
    !HIDDEN_TABS.includes('source_manager')
      ? [
          {
            title: 'Source Manager',
            link: '/source_manager/',
            icon: <DatabaseTwoTone />,
            description: 'Manage source data.',
          },
        ]
      : []),
  ];

  return (
    <>
      {!Auth.isLoggedIn() && (
        <div>
          <Button type="primary" href="/login">
            Login to TRACK
          </Button>
          <Divider />
        </div>
      )}
      <h1>Quick Links</h1>
      <div className="flex-row">
        {links.map((link) => {
          return (
            <Link to={link.link} key={link.title}>
              <LinkCard>
                <h2>
                  {link.icon} {link.title}
                </h2>
                <p>{link.description}</p>
              </LinkCard>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default HomePage;
