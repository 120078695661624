import {useFetchSchoolsQuery} from 'api/apiSlice';
import HSScheduleTable from 'components/HSScheduleTable';
import LastRefresh from 'components/LastRefresh';
import SchoolDropdown from 'components/SchoolDropdown';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const HSSchedulePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {schools} = useFetchSchoolsQuery(
    {},
    {
      selectFromResult: ({data}) => ({
        schools: data?.schools,
      }),
    }
  );

  const chosenSchoolAbbv = location.pathname.split('/')[2];
  const [chosenSchool, setChosenSchool] = useState(null);

  useEffect(() => {
    if (schools) {
      let chosenSchool;
      if (schools.length === 1) {
        chosenSchool = 0;
        if (!chosenSchoolAbbv) {
          navigate(`/hs_schedule/${schools[0].abbreviation}`);
        }
      } else {
        chosenSchool = schools.find((e) => e.abbreviation === chosenSchoolAbbv);
      }
      setChosenSchool(chosenSchool);
    }
  }, [navigate, schools?.length]);

  const onSchoolSelectChange = (newSchool) => {
    // We want to move to a new url for the school, plus we want to clear the filters.
    setChosenSchool(newSchool);
    navigate(`/hs_schedule/${newSchool.abbreviation}`);
  };

  return (
    <>
      <div>
        <h1>AP Index Section Tool</h1>

        {/* Display the school 
        selection and audit table when we have school data. */}
        {schools && (
          <div>
            <LastRefresh />
            <SchoolDropdown
              onSchoolSelectChange={onSchoolSelectChange}
              chosenSchool={chosenSchool}
              key={chosenSchool?.site_id}
              schools={schools}
              filters={{gradespan: ['HS']}}
            />
          </div>
        )}

        {schools && !!chosenSchool && (
          <HSScheduleTable
            key={chosenSchool?.site_id}
            siteId={chosenSchool?.site_id}
          />
        )}
      </div>
    </>
  );
};

export default HSSchedulePage;
