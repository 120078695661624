import Auth from 'Auth';
import {Drawer, Spin, Switch} from 'antd';
import {useFetchPageQuery, useUpdatePageMutation} from 'api/cmsSlice';
import PageForm from 'components/dataPortal/PageForm';
import EditorButton from 'components/genericComponents/EditorButton';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const PageDrawer = () => {
  const location = useLocation();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [localPage, setLocalPage] = useState();
  const [localWidgets, setLocalWidgets] = useState([]);

  const {data, isLoading} = useFetchPageQuery(
    {
      page_slug: pageSlug,
      version: location.search.split('=')[1] || 'latest',
    },
    {skip: !pageSlug}
  );

  const [updatePage] = useUpdatePageMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (!data || isEqual(data, localPage)) return;
    setLocalPage({
      ...data,
    });
    setLocalWidgets(data.widgets);
  }, [data]);

  return (
    <>
      <Drawer
        closable={true}
        extra={
          <Switch
            loading={isLoading}
            disabled={isLoading}
            checked={localPage?.published}
            onChange={() =>
              updatePage({...localPage, published: !localPage?.published})
            }
            checkedChildren="Unpublish"
            unCheckedChildren="Publish"
            type={localPage?.published ? 'danger' : 'primary'}
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
        title="Page Editor"
        width={600}
      >
        <Spin spinning={isLoading}>
          <PageForm
            data={data}
            pageSlug={pageSlug}
            localPage={localPage}
            setLocalPage={setLocalPage}
            localWidgets={localWidgets}
            setLocalWidgets={setLocalWidgets}
          />
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        path={`/data_portal/${pageSlug}`}
        key={pageSlug + editorMode}
      />
    </>
  );
};

export default PageDrawer;
