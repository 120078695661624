import {
  CloseCircleOutlined,
  RightOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import {Alert, Button, Popover, Tooltip} from 'antd';
import DimensionForm from 'components/dataReports/DimensionForm';
import MetricForm from 'components/dataReports/MetricForm';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 2px;
  padding: 10px;
  margin: 5px 0;
  &:hover {
    border-color: #aaa;
  }
  &:focus {
    border-color: #1890ff;
  }
`;

const SortButton = ({column, setColumn}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const sortOptions = ['ascend', 'descend', undefined];

  const onClick = (e) => {
    e.stopPropagation();
    const idx = sortOptions.indexOf(column?.sort);
    const newSort = sortOptions[(idx + 1) % sortOptions.length];
    setColumn(newSort, 'sort');

    navigate(location.pathname);
  };

  return (
    // cycle between sort options (ascend, descend, undefined), and set sort_priority
    <>
      <Tooltip
        title={
          !column.sort
            ? 'Sort'
            : column.sort === 'ascend'
              ? 'Sort Descending'
              : 'Remove Sort'
        }
      >
        <Button type="text" size="small" onClick={onClick}>
          {column.sort === 'ascend' ? (
            <SortAscendingOutlined style={{color: '#ffdd00'}} />
          ) : column.sort === 'descend' ? (
            <SortDescendingOutlined style={{color: '#ffdd00'}} />
          ) : (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>&#8645;</span>
          )}
        </Button>
      </Tooltip>
    </>
  );
};

const ColumnForm = ({
  columns,
  column,
  setColumn,
  handleDelete,
  renderVisible,
  idx,
}) => {
  const [visible, setVisible] = useState(renderVisible);

  const onClick = () => {
    setVisible(false);
    handleDelete();
  };

  const getColumnForm = () => {
    return (
      <>
        {column.errors?.length > 0 && (
          <Alert
            message={
              <div>
                {column.errors?.map((error) => (
                  <div key={error}>* {error}</div>
                ))}
              </div>
            }
            type="warning"
            style={{margin: '10px', fontSize: '12px'}}
          />
        )}
        <div className="flex-column" style={{alignItems: 'stretch'}}>
          <SelectOptions
            options={['DIMENSION', 'METRIC']}
            selectedOption={column.column_type}
            changeSelection={(value) => setColumn(value, 'column_type')}
            label="Column Type"
            required={true}
          />
          {column.column_type === 'METRIC' ? (
            <MetricForm
              columns={columns}
              metric={column}
              setMetric={setColumn}
            />
          ) : (
            <DimensionForm
              columns={columns}
              dimension={column}
              setDimension={setColumn}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <Draggable key={column.id} draggableId={column.id} index={idx}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Popover
            placement="right"
            open={visible}
            onOpenChange={(visible) => {
              if (!visible) {
                setColumn(false, 'renderVisible');
              }
              setVisible(visible);
            }}
            content={getColumnForm()}
            trigger="click"
            style={{
              width: '354px',
            }}
          >
            <div
              className="flex-row"
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Remove">
                <CloseCircleOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                />
              </Tooltip>
              {column.column_type && (
                <span>
                  <strong style={{fontSize: '12px'}}>
                    {column.column_type?.toUpperCase()}
                  </strong>
                </span>
              )}
              <span>
                {column.alias ?? column.source_column}
                {column.errors?.length > 0 && (
                  <span style={{color: 'red', marginLeft: '5px'}}>*</span>
                )}
              </span>
              <span style={{flex: 1}} />
              <SortButton column={column} setColumn={setColumn} />
              <Tooltip title="Freeze column">
                <Button
                  type="text"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setColumn(!column.freeze, 'freeze');
                  }}
                >
                  <span
                    style={{
                      color: column.freeze ? '#ffdd00' : 'rgba(0, 0, 0, 0.25)',
                      fontWeight: 600,
                    }}
                  >
                    &#10052;
                  </span>
                </Button>
              </Tooltip>
              <RightOutlined />
            </div>
          </Popover>
        </Container>
      )}
    </Draggable>
  );
};

export default ColumnForm;
