import {EditOutlined, EyeOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Divider, Spin, Tooltip} from 'antd';
import {useFetchAggregateReportsListQuery} from 'api/reportsSlice';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const DataReportOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [reportsToDisplay, setReportsToDisplay] = useState([]);

  const {data: reports, isLoading} = useFetchAggregateReportsListQuery();

  useEffect(() => {
    if (reports) {
      setReportsToDisplay(
        editorMode ? reports.reportsByCategory : reports.savedReports
      );
    }
  }, [reports, editorMode]);

  return (
    <div>
      <h1>Data Reports</h1>
      <div
        style={{
          padding: '20px',
        }}
      >
        {editorMode && (
          <Button
            type="primary"
            href="/data_report/new/editor"
            style={{marginBottom: 20}}
          >
            Create New Report
          </Button>
        )}
        <Spin spinning={isLoading}>
          {reports && Object.keys(reportsToDisplay).length ? (
            Object.keys(reportsToDisplay).map((folder) => (
              <div key={folder}>
                <h3>{folder}</h3>
                {reportsToDisplay[folder]?.map((report) => (
                  <div key={report.id}>
                    <Tooltip
                      title={report.long_description}
                      placement="bottomLeft"
                    >
                      <Link
                        to={`/data_report/${report.id}${editorMode ? '/editor' : ''}`}
                        style={{fontSize: '16px'}}
                      >
                        {report.report_name}
                      </Link>
                    </Tooltip>
                    <p>{report.short_description}</p>
                  </div>
                ))}
                <Divider />
              </div>
            ))
          ) : (
            <div>There are no saved reports yet.</div>
          )}
        </Spin>
      </div>
      {Auth.permissions.access_to_data_reports_editor ? (
        <Tooltip
          title={editorMode ? 'Preview mode' : 'Editor mode'}
          placement="right"
        >
          <Button
            onClick={() =>
              navigate(`/data_report${editorMode ? '' : '/editor'}`)
            }
            type="primary"
            style={{
              top: '15%',
              left: 0,
              height: '50px',
              position: 'fixed',
              zIndex: 1001,
              fontSize: '1.8em',
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default DataReportOverviewPage;
