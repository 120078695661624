import {useFetchSchoolsQuery} from 'api/apiSlice';
import LastRefresh from 'components/LastRefresh';
import SchoolDropdown from 'components/SchoolDropdown';
import AuditRulesTable from 'components/audits/AuditRulesTable';
import AuditsIssueOverviewTable from 'components/audits/AuditsIssueOverviewTable';
import AuditsOverviewTable from 'components/audits/AuditsOverviewTable';
import AuditsSchoolSummaryTable from 'components/audits/AuditsSchoolSummaryTable';
import AuditsTable from 'components/audits/AuditsTable';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const AuditsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const schoolAbbv = location.pathname.split('/')[2];
  const path = location.pathname.split('/')[1];

  const {data} = useFetchSchoolsQuery();

  const [chosenSchool, setChosenSchool] = useState();
  const [chosenRegion, setChosenRegion] = useState('All');
  const [title, setTitle] = useState('Audits Table');

  useEffect(() => {
    if (path === 'audit_rules') {
      setTitle('Audit Rules');
    } else if (path === 'audits_issue_overview') {
      setTitle('Audit Issue Overview');
    } else if (path === 'audits_overview') {
      setTitle('Audit Overview');
    } else if (path === 'audits_school_summary') {
      setTitle('Audits School Summary');
    } else if (path === 'audits') {
      setTitle('Audits Table');
    } else {
      setTitle('');
    }
  }, [path]);

  useEffect(() => {
    if (!['audits', 'audits_school_summary'].includes(path)) {
      return;
    }
    let school = chosenSchool;
    if (!chosenSchool && schoolAbbv) {
      school = data?.schools?.find((s) => s.abbreviation === schoolAbbv);
    } else if (data?.schools?.length === 1 && !schoolAbbv) {
      navigate(`/${path}/${data.schools[0].abbreviation}`);
      school = data.schools[0];
    } else if (!schoolAbbv && chosenSchool) {
      navigate(`/${path}/${chosenSchool.abbreviation}`);
      return;
    }
    setChosenSchool(school);
  }, [data, schoolAbbv, path]);

  const onSchoolSelectChange = (newSchool) => {
    // we want to change the url to reflect the new school
    navigate(`/${path}/${newSchool.abbreviation}`);
    setChosenSchool(newSchool);
  };

  return (
    <div>
      <h1>{title}</h1>
      {[
        'audits_issue_overview',
        'audits_overview',
        'audits_school_summary',
        'audits',
      ].includes(path) && <LastRefresh />}

      {['audits', 'audits_school_summary'].includes(path) && (
        <>
          {!data ? (
            <div>Loading schools list...</div>
          ) : (
            <SchoolDropdown
              schools={data.schools}
              chosenSchool={chosenSchool}
              onSchoolSelectChange={onSchoolSelectChange}
              style={{
                marginBottom: 20,
              }}
            />
          )}
        </>
      )}

      {['audits_overview', 'audits_issue_overview'].includes(path) &&
        data?.regions && (
          <SelectOptions
            size="middle"
            options={data.regions.map((region) => ({
              value: region,
              label: (
                <>
                  <strong>Region:</strong> {region}
                </>
              ),
            }))}
            selectedOption={chosenRegion}
            changeSelection={setChosenRegion}
            showSearch
          />
        )}

      {path === 'audits_overview' && (
        <AuditsOverviewTable chosenRegion={chosenRegion} key={chosenRegion} />
      )}
      {path === 'audits_issue_overview' && (
        <AuditsIssueOverviewTable
          chosenRegion={chosenRegion}
          key={chosenRegion}
        />
      )}
      {path === 'audits_school_summary' && data?.schools && chosenSchool && (
        <AuditsSchoolSummaryTable
          key={chosenSchool.abbreviation}
          siteId={chosenSchool.site_id}
          siteAbbr={chosenSchool.abbreviation}
        />
      )}
      {path === 'audits' && data?.schools && chosenSchool && (
        <AuditsTable
          key={chosenSchool.abbreviation}
          siteId={chosenSchool.site_id}
        />
      )}
      {path === 'audit_rules' && <AuditRulesTable />}
    </div>
  );
};

export default AuditsPage;
