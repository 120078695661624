import {Divider} from 'antd';
import TaskComplete from 'components/TaskComplete';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
`;

const EoyChecks = ({siteId}) => {
  return (
    <div>
      <h3>Before leaving for Ops Shutdown, have you…</h3>
      <Divider />
      <Container>
        <span>(MS Only) Floored all grades (no stored grade &#60;55)</span>
        <TaskComplete
          siteId={siteId}
          taskName="floored_all_grades"
          isTaskCompletable={() => true}
          taskIncompleteableMessage={<></>}
          taskCompleteableMessage={<>Can mark task as complete.</>}
          colSpan={8}
        />
      </Container>
      <Divider />
      <Container>
        <span>Generate and archive report cards for each quarter</span>
        <TaskComplete
          siteId={siteId}
          taskName="generated_archived_report_cards"
          isTaskCompletable={() => true}
          taskIncompleteableMessage={<></>}
          taskCompleteableMessage={<>Can mark task as complete.</>}
          colSpan={8}
        />
      </Container>
      <Divider />
      <Container>
        <span>
          Complete required exit fields (example: comment/code) for all students
          leaving Uncommon
        </span>
        <TaskComplete
          siteId={siteId}
          taskName="added_exit_comment_code_fields_for_all_students"
          isTaskCompletable={() => true}
          taskIncompleteableMessage={<></>}
          taskCompleteableMessage={<>Can mark task as complete.</>}
          colSpan={8}
        />
      </Container>
    </div>
  );
};

export default EoyChecks;
