import {Table} from 'antd';
import React from 'react';

const DiffCompare = ({diffs, diffCols}) => {
  const getColumns = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        width: 30,
        render: (text, record) => {
          if (record.class === 'diff-insert') {
            return '+';
          } else if (record.class === 'diff-delete') {
            return '-';
          }
          return '+-';
        },
        filters: [
          {
            text: '+',
            value: 'diff-insert',
          },
          {
            text: '-',
            value: 'diff-delete',
          },
          {
            text: '+-',
            value: 'diff-modify',
          },
        ],
        onFilter: (value, record) => record.class === value,
      },
    ];
    diffCols?.forEach((col) => {
      columns.push({
        title: col,
        dataIndex: col,
        key: col,
        onCell: (record) => {
          return {
            className:
              record.to && record.to[col] !== undefined
                ? 'diff-modify-cell'
                : 'diff',
          };
        },
        render: (text, record) => {
          if (record.class === 'diff-modify') {
            return (
              <>
                <div className="strikethrough">
                  {record[col] ?? ' \u{23AF}'}
                </div>
                <div>{record.to?.[col]}</div>
              </>
            );
          }
          return text;
        },
      });
    });
    return columns;
  };

  const getData = () => {
    const data = [];
    diffs?.forEach((diff, i) => {
      if (!diff.from) {
        data.push({...diff.to, class: 'diff-insert', key: i});
      } else if (!diff.to) {
        data.push({...diff.from, class: 'diff-delete', key: i});
      } else {
        data.push({
          ...diff.from,
          class: 'diff-modify',
          to: diff.to,
          key: i,
        });
      }
    });
    return data;
  };

  return (
    <Table
      dataSource={getData()}
      columns={getColumns()}
      pagination={false}
      size="small"
      bordered
      scroll={{x: '100%'}}
      rowClassName={(record) => {
        return [record.class];
      }}
      rowKey={(record) => record.key}
    />
  );
};

export default DiffCompare;
