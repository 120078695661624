import UCPersonLookup from 'components/UCPersonLookup';
import React from 'react';

function UCPersonPage() {
  return (
    <div>
      <h1>Uncommon Person Lookup</h1>
      <UCPersonLookup />
    </div>
  );
}

export default UCPersonPage;
