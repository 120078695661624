import {setContrast} from 'Utils';
import {Breadcrumb, Spin, Tag} from 'antd';
import {useFetchPageQuery} from 'api/cmsSlice';
import DataTableDisplay from 'components/dataPortal/DataTableDisplay';
import SupersetDisplay from 'components/dataPortal/SupersetDisplay';
import RichTextDisplay from 'components/genericComponents/RichTextDisplay';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const DataPortalDisplay = ({editorMode}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';
  const params = new URLSearchParams(location.search);
  const [version, setVersion] = useState(editorMode ? 'latest' : 'published');

  const {data, isLoading, refetch} = useFetchPageQuery(
    {
      page_slug: pageSlug,
      version,
    },
    {skip: !pageSlug}
  );

  useEffect(() => {
    if (!editorMode) {
      setVersion('published');
    } else if (params.get('version')) {
      setVersion(params.get('version'));
    } else {
      setVersion('latest');
    }
  }, [params.get('version')]);

  useEffect(() => {
    if (!data) {
      return;
    }
    // if the pageSlug, editorMode, or version query param changes, refetch the page
    refetch();
  }, [pageSlug, editorMode, version]);

  useEffect(() => {
    if (!data) return;
    // add params to the url for each report
    data.widgets.forEach((widget) => {
      params.set(widget.source, params.get(widget.source) || '');
    });
  }, [data]);

  const setParams = (newParams, reportId) => {
    // get the current params
    // the url params need to handle filters and pagination for each report separately
    // so we need to keep track of the params for each report
    // we can do this by setting the reportId as the key for the params
    const currentParams = params.get(reportId) || '';
    if (currentParams === newParams) {
      return;
    }
    // update the params for the report
    params.set(reportId, newParams);
    // update the url with the new params
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const getQueryParams = (reportId) => {
    const reportParams = params.get(reportId) || '';
    const urlParams = new URLSearchParams(reportParams);
    // remove 'filter_' from the keys
    for (const entry of urlParams.entries()) {
      urlParams.set(entry[0].replace('filter_', ''), entry[1]);
    }
    return urlParams;
  };

  const setGoogleSource = (val) => {
    try {
      const url = new URL(val);
      const path = url.pathname.split('/');
      const type = path[1];
      const id = path[3];
      let source = '';
      if (type === 'document') {
        source = `https://docs.google.com/document/d/${id}/preview`;
      } else if (type === 'spreadsheets') {
        source = `https://docs.google.com/spreadsheets/d/${id}/htmlview`;
      } else if (type === 'presentation') {
        source = `https://docs.google.com/presentation/d/${id}/embed`;
      }
      return source;
    } catch (error) {
      console.error('Error parsing google url', error);
      return val;
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/data_portal/${editorMode ? 'editor' : ''}`}>
            Data Portal
          </Link>
        </Breadcrumb.Item>
        {data?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          <Link to={location.pathname.replace('/editor', '')}>
            {data?.title}
          </Link>
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <h1>{data?.title}</h1>
      <div className="flex-row" style={{marginBottom: '20px', gap: 0}}>
        {data?.tags?.map((tag) => {
          const textColor = setContrast(tag.color);
          return (
            <Link
              to={`/data_portal/${editorMode ? 'editor' : ''}?tag=${encodeURIComponent(tag.name)}`}
              key={tag.name}
            >
              <Tag
                color={tag.color}
                style={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: textColor,
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                  cursor: 'pointer',
                }}
              >
                {tag.name}
              </Tag>
            </Link>
          );
        })}
      </div>
      <div
        className="flex-column"
        style={{
          marginBottom: '20px',
        }}
      >
        {data?.widgets.map((widget) => {
          if (widget.type === 'text') {
            return widget.content ? (
              <RichTextDisplay key={widget.id} value={widget.content} />
            ) : null;
          } else if (widget.type === 'table') {
            return widget.source ? (
              <DataTableDisplay
                key={widget.id}
                reportId={widget.source}
                params={getQueryParams(widget.source)}
                setParams={(value) => setParams(value, widget.source)}
              />
            ) : null;
          } else if (widget.type === 'dashboard') {
            return widget.source && widget.dashboard_id ? (
              <SupersetDisplay
                key={widget.id}
                source={widget.source}
                dashboardId={widget.dashboard_id}
              />
            ) : null;
          } else if (widget.type === 'google' || widget.type === 'pdf') {
            return widget.source ? (
              <div key={widget.id}>
                <div>
                  Link to Source:{' '}
                  <a href={widget.source} target="_blank" rel="noreferrer">
                    {widget.source}
                  </a>
                </div>
                <iframe
                  src={
                    widget.type === 'google'
                      ? setGoogleSource(widget.source)
                      : widget.source
                  }
                  width="100%"
                  height="800"
                  frameBorder="0"
                  style={{border: '1px solid #939598'}}
                />
              </div>
            ) : null;
          } else {
            return null;
          }
        })}
        {!data?.widgets.length && <div>No widgets found</div>}
      </div>
    </Spin>
  );
};

export default DataPortalDisplay;
