import Auth from 'Auth';
import {handleApiError} from 'errorHandler';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ProtectedRoute = ({component: Component, ...rest}) => {
  const location = useLocation();

  useEffect(() => {
    if (!Auth.isLoggedIn()) {
      handleApiError({
        status: 401,
        data: {
          detail: 'Unauthorized',
        },
      });
    }
    if (Auth.isLoggedIn() && !Auth.hasPermission(location.pathname)) {
      handleApiError({
        status: 403,
        data: {
          detail: 'Permission denied',
        },
      });
    }
  }, [location]);

  return <Component {...rest} />;
};

export default ProtectedRoute;
