import {Modal} from 'antd';
import React, {Suspense, lazy, useMemo, useRef, useState} from 'react';

const JoditEditor = lazy(() => import('jodit-react'));

const RichTextEditor = ({initialValue, onChange, visible, setVisible}) => {
  const editor = useRef(null);
  const [content, setContent] = useState(initialValue);

  const handleClose = (save = true) => {
    if (save) {
      onChange(content);
    } else {
      setContent(initialValue);
    }
    setVisible(false);
  };

  const config = useMemo(
    () => ({
      addNewLine: false,
      askBeforePasteHTML: false,
      autofocus: true,
      // beautifyHTML: true,
      buttons: [
        'undo',
        'redo',
        '|',
        'bold',
        'strikethrough',
        'underline',
        'italic',
        '|',
        'ul',
        'ol',
        'superscript',
        'subscript',
        '|',
        'outdent',
        'indent',
        'align',
        '|',
        'font',
        'fontsize',
        'lineHeight',
        'brush',
        'paragraph',
        '|',
        'file',
        'image',
        'video',
        'link',
        'table',
        'symbols',
        'hr',
        '|',
        'cut',
        'copy',
        'paste',
        'selectall',
        '|',
        'eraser',
        'copyformat',
        'spellcheck',
        '|',
        'print',
        'about',
      ],
      cleanHTML: {
        replaceNBSP: false,
        fillEmptyParagraph: false,
      },
      colorPickerDefaultTab: 'text',
      colors: {
        greyscale: [
          '#000000',
          '#434343',
          '#666666',
          '#999999',
          '#B7B7B7',
          '#CCCCCC',
          '#D9D9D9',
          '#EFEFEF',
          '#F3F3F3',
          '#FFFFFF',
        ],
        palette: [
          '#1034B4',
          '#FFB500',
          '#000000',
          '#FFFFFF',
          '#A60056',
          '#FF6800',
          '#00973A',
          '#4500A4',
        ],
        full: [
          '#E6B8AF',
          '#F4CCCC',
          '#FCE5CD',
          '#FFF2CC',
          '#D9EAD3',
          '#D0E0E3',
          '#C9DAF8',
          '#CFE2F3',
          '#D9D2E9',
          '#EAD1DC',
          '#DD7E6B',
          '#EA9999',
          '#F9CB9C',
          '#FFE599',
          '#B6D7A8',
          '#A2C4C9',
          '#A4C2F4',
          '#9FC5E8',
          '#B4A7D6',
          '#D5A6BD',
          '#CC4125',
          '#E06666',
          '#F6B26B',
          '#FFD966',
          '#93C47D',
          '#76A5AF',
          '#6D9EEB',
          '#6FA8DC',
          '#8E7CC3',
          '#C27BA0',
          '#A61C00',
          '#CC0000',
          '#E69138',
          '#F1C232',
          '#6AA84F',
          '#45818E',
          '#3C78D8',
          '#3D85C6',
          '#674EA7',
          '#A64D79',
          '#85200C',
          '#990000',
          '#B45F06',
          '#BF9000',
          '#38761D',
          '#134F5C',
          '#1155CC',
          '#0B5394',
          '#351C75',
          '#733554',
          '#5B0F00',
          '#660000',
          '#783F04',
          '#7F6000',
          '#274E13',
          '#0C343D',
          '#1C4587',
          '#073763',
          '#20124D',
          '#4C1130',
        ],
      },
      cursorAfterAutofocus: 'end',
      debugLanguage: false,
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      // direction: '',
      disablePlugins: [
        'ai-assistant',
        // 'class-span',
        'iframe',
        // 'image-processor',
        // 'image-properties',
        // 'inline-popup',
        // 'key-arrow-outside',
        'limit',
        'mobile',
        'search',
        'source',
        'speech-recognize',
        'stat',
        'xpath',
        'wrap-nodes',
      ],
      editorCssClass: false,
      enter: 'P',
      // extraButtons: [],
      height: 'auto',
      // iframe: true,
      i18n: 'en',
      inline: true,
      language: 'en',
      link: {
        followOnDblClick: true,
        processVideoLink: true,
        processPastedLink: true,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        modeClassName: 'none',
        // selectMultipleClassName: true,
        // selectSizeClassName: 10,
        // selectOptionsClassName: [
        //   {value: 'https://xdsoft.net', text: 'xdsoft'}
        // ],
      },
      minHeight: 300,
      // popup: { // options for inline toolbar
      //   a: ['link', 'unlink']
      // },
      processPasteHTML: false,
      readonly: false,
      // removeButtons: [],
      removeButtonsText: false,
      saveModeInCookie: false,
      saveModeInStorage: false,
      saveSelectionOnBlur: true,
      spellcheck: true,
      // sizeLG: 900,
      // sizeMD: 700,
      // sizeSM: 400,
      showCharsCounter: false,
      showTooltip: true,
      showWordsCounter: false,
      showWordsCounterInToolbar: false,
      showXPathInStatusbar: false,
      statusBar: false,
      stickyToolbar: true,
      tabIndex: -1,
      tableAllowCellResize: true,
      tableAllowCellSelection: true,
      // tableKeyboardNavigation: true,
      theme: 'default',
      textIcons: false,
      toolbar: true,
      toolbarAdaptive: true,
      toolbarButtonSize: 'small',
      // triggerChangeEvent: true,
      // toolbarInline: true,
      // toolbarInlineDisabledButtons: ['source', 'addcolumn', 'addrow', 'delete'],
      // toolbarInlineDisableFor: ['source', 'addcolumn', 'addrow', 'delete'],
      // toolbarInlineForSelection: true,
      toolbarSticky: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
      useExtraClassesOptions: true,
      useNativeTooltip: false,
      usePopupForSpecialCharacters: true,
      // useSearch: true,
      useSplitMode: false,
      width: 600,
    }),
    [initialValue]
  );

  return (
    <Modal
      className="jodit-editor"
      open={visible}
      style={{zIndex: 1002}}
      width={1000}
      closable={false}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={() => handleClose(false)}
      onOk={() => handleClose(true)}
    >
      <Suspense fallback={<div>Loading editor...</div>}>
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}
          config={config}
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={() => {}}
        />
      </Suspense>
    </Modal>
  );
};

export default RichTextEditor;
