import {Input} from 'antd';
import React from 'react';

const {TextArea} = Input;

const InputField = ({
  placeholder,
  value,
  onChange,
  type,
  multiline,
  disabled,
  icon,
  label = '',
  style = [],
  required = false,
  size = 'large',
}) => {
  return (
    <div
      style={{
        justifyContent: 'left',
        position: 'relative',
        minWidth: 100,
        ...style,
      }}
    >
      {label && (
        <label
          style={{
            position: 'absolute',
            top: -6,
            left: 6,
            backgroundColor: 'white',
            padding: '0 5px',
            color: 'gray',
            zIndex: 2,
            fontSize: 10,
          }}
        >
          {label}
          {required && <span>*</span>}
        </label>
      )}
      {multiline ? (
        <TextArea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          allowClear
          disabled={disabled}
        />
      ) : (
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type ?? 'text'}
          allowClear
          disabled={disabled}
          suffix={icon}
          size={size}
        />
      )}
    </div>
  );
};

export default InputField;
