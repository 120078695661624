import {Popover, Switch, message} from 'antd';
import {
  useFetchTaskStatusBySchoolQuery,
  useSetTaskStatusMutation,
} from 'api/esoySlice';
import React, {useEffect} from 'react';

const TaskComplete = ({
  siteId,
  taskName,
  setCompletionStatus,
  isTaskCompletable,
  taskCompleteableMessage,
  taskIncompleteableMessage,
}) => {
  const {data} = useFetchTaskStatusBySchoolQuery(siteId);
  const [setTaskStatus] = useSetTaskStatusMutation();

  useEffect(() => {
    if (setCompletionStatus && data?.[taskName]?.completed) {
      setCompletionStatus(data?.[taskName]?.completed);
    }
  }, [data]);

  const taskCanBeCompleted = () => {
    if (data?.[taskName]?.completed) return false; // Already done.
    if (!isTaskCompletable) return false; // Task is not completable.
    return isTaskCompletable();
  };

  const onTaskCompleteSwitchPressed = () => {
    const key = 'taskSave';
    message.loading({
      content: 'Saving...',
      key,
    });
    setTaskStatus({siteId, taskName, status: true}).then((response) => {
      if (setCompletionStatus) {
        setCompletionStatus(true);
      }
    });
  };

  return (
    <div
      style={{
        padding: '10px',
        border: '1px solid lightgrey',
        width: 220,
        float: 'right',
      }}
    >
      This task is complete: &nbsp;
      <Popover
        content={
          <>
            {/* If task is completed */}
            {data?.[taskName]?.completed ? (
              <>
                <p>Marking task completed cannot be undone. </p>
                <p>Please contact IT Support if you would like to undo this.</p>
              </> // Not complete:
            ) : taskCanBeCompleted() ? (
              <>
                <p>{taskCompleteableMessage}</p>
                <p>This cannot be undone without contacting IT Support.</p>
              </>
            ) : (
              <p>{taskIncompleteableMessage}</p>
            )}
          </>
        }
      >
        <Switch
          // loading={taskCompleteLoading}
          disabled={!taskCanBeCompleted()}
          onChange={(e) => onTaskCompleteSwitchPressed(e)}
          checked={data?.[taskName]?.completed}
        />
      </Popover>
    </div>
  );
};

export default TaskComplete;
