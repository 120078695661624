import {setContrast} from 'Utils';
import {Tooltip} from 'antd';
import React, {useState} from 'react';
import {SketchPicker} from 'react-color';
import reactCSS from 'reactcss';

const SketchExample = ({initialValue, onChange}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialValue ?? '#fff');
  const [textColor, setTextColor] = useState(
    initialValue ? setContrast(initialValue) : '#000000'
  );

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    onChange(color);
  };

  const handleChange = (color) => {
    setColor(color.hex);
    setTextColor(setContrast(color.hex));
  };

  const styles = reactCSS({
    default: {
      color: {
        background: color,
        borderRadius: '50%',
        height: '10px',
        textAlign: 'center',
        width: '10px',
      },
      swatch: {
        background: '#fff',
        borderRadius: '50%',
        boxShadow: `0 0 0 1px ${textColor}`,
        cursor: 'pointer',
        display: 'inline-block',
        height: '10px',
        width: '10px',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        bottom: '0px',
        left: '0px',
        position: 'fixed',
        right: '0px',
        top: '0px',
      },
    },
  });

  return (
    <>
      <Tooltip title="Edit color">
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color}>{/* <FireFilled /> */}</div>
        </div>
      </Tooltip>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker
            color={color}
            disableAlpha={true}
            onChange={handleChange}
            presetColors={[
              '#1034B4',
              '#FFB500',
              '#000000',
              '#FFFFFF',
              '#A60056',
              '#FF6800',
              '#00973A',
              '#4500A4',
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

export default SketchExample;
