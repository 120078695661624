import {MinusCircleOutlined} from '@ant-design/icons';
import {getDisplayGrade, pluralizeGrade} from 'Utils';
import {Form, Input, Select, Table, message} from 'antd';
import {useFetchHomeroomsQuery, useSetHomeroomsMutation} from 'api/esoySlice';
import React, {useContext, useEffect, useRef, useState} from 'react';

const {Option} = Select;
const EditableContext = React.createContext(null);

const EditableRow = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const HomeroomNamingCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) inputRef.current.focus();
  }, [editing]);

  const toggleEdit = () => setEditing(!editing);

  const save = async () => {
    // If no name was input, revert to saved name and exit edit mode.
    if (!form.getFieldsValue()[dataIndex[1]]) {
      setEditing(false);
      return;
    }
    const values = await form.validateFields();
    toggleEdit();
    const newRecord = {...record, homeroom_names: [...record.homeroom_names]};
    newRecord.homeroom_names[dataIndex[1]] = Object.entries(values)[0][1];
    handleSave(newRecord);
  };

  // First, figure out if this homeroom is even "enabled",
  // meaning if the length of the homerooms array (for this grade) indicates
  // that this "column" exists.
  // dataIndex is the index into the homeroom array we are looking for, so we can just compare
  // it to the record.
  if (dataIndex && parseInt(dataIndex[1]) >= record.homeroom_names.length) {
    return (
      <td {...restProps}>
        <MinusCircleOutlined />
      </td>
    );
  }

  if (editable) {
    children = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={dataIndex[1]}
        rules={[
          {required: true, message: `${title} is required.`},
          {max: 60, message: `Maximum homeroom length is 60`},
          {
            pattern: /^[\w '&]*$/,
            message: `Only allowed characters are a-z, 0-9, space, &, ',`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }

  return <td {...restProps}>{children}</td>;
};

const getColumnsForTable = (completed, onNumHomeroomsChange, handleSave) => [
  {
    title: 'Grade',
    dataIndex: 'grade',
    width: '15%',
    align: 'right',
    render: getDisplayGrade,
  },
  {
    title: '# of Homerooms',
    dataIndex: ['homeroom_names', 'length'],
    align: 'right',
    render: (num_homerooms, record) => (
      <Select
        style={{width: 60}}
        onChange={(e) => onNumHomeroomsChange(e, record.grade)}
        value={num_homerooms}
        disabled={completed}
      >
        {[1, 2, 3, 4].map((num) => (
          <Option key={num} value={num}>
            {num}
          </Option>
        ))}
      </Select>
    ),
  },
  ...['A', 'B', 'C', 'D'].map((letter, index) => ({
    title: `Homeroom ${letter}`,
    dataIndex: ['homeroom_names', index],
    width: '20%',
    editable: !completed,
    onCell: (record) => ({
      record,
      editable: !completed,
      dataIndex: ['homeroom_names', index],
      title: `Homeroom ${letter}`,
      handleSave,
    }),
  })),
];

const HomeroomNamingTable = ({siteId, completed, setAllHomeroomsNamed}) => {
  const {data: homerooms, isLoading} = useFetchHomeroomsQuery(siteId);
  const [setSchoolHomerooms] = useSetHomeroomsMutation();

  useEffect(() => {
    setAllHomeroomsNamed(allHomeroomsNamed());
  }, [homerooms]);

  const saveHomeroomNames = (newDataSource) => {
    const newHomeroomNames = newDataSource.reduce((acc, grade) => {
      acc[grade.key] = grade.homeroom_names.map((name, i) => ({
        homeroom_title: name,
        homeroom_id: grade.gradeHomeroomIds[i] || undefined,
      }));
      return acc;
    }, {});
    setSchoolHomerooms({siteId, homerooms: newHomeroomNames})
      .unwrap()
      .then(() => {
        message.success('Homeroom names saved successfully');
      })
      .catch(() => {
        message.error('Error saving homeroom names');
      });
  };

  const getDefaultHomeroomName = (grade, position) => {
    const LETTERS = 'ABCD';
    return `${pluralizeGrade(grade)} Grade homeroom ${LETTERS[position]}`;
  };

  const onNumHomeroomsChange = (newNum, grade) => {
    const newData = homerooms.map((g) => {
      if (g.grade !== grade) return g;
      const homeroom_names =
        newNum > g.homeroom_names.length
          ? [
              ...g.homeroom_names,
              ...Array(newNum - g.homeroom_names.length)
                .fill()
                .map((_, i) =>
                  getDefaultHomeroomName(grade, g.homeroom_names.length + i)
                ),
            ]
          : g.homeroom_names.slice(0, newNum);
      return {
        ...g,
        homeroom_names,
        gradeHomeroomIds: g.gradeHomeroomIds.slice(0, newNum),
      };
    });
    saveHomeroomNames(newData);
  };

  const handleSave = (row) => {
    const newData = homerooms.map((item) =>
      item.key === row.key ? {...item, ...row} : item
    );
    saveHomeroomNames(newData);
  };

  const allHomeroomsNamed = () => {
    if (!homerooms) return false;
    return homerooms.every((grade) =>
      grade.homeroom_names.every(
        (name, i) => name !== getDefaultHomeroomName(grade.grade, i)
      )
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: HomeroomNamingCell,
    },
  };

  return (
    <div>
      <Table
        loading={isLoading}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={homerooms}
        columns={getColumnsForTable(
          completed,
          onNumHomeroomsChange,
          handleSave
        )}
        pagination={false}
        scroll={{x: '100%'}}
      />
    </div>
  );
};

export default HomeroomNamingTable;
