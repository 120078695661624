import {Alert, Button, InputNumber, Table, Tooltip, message} from 'antd';
import {
  useFetchHSSchedulingQuery,
  useLoadOptimalScheduleMutation,
  useSaveHSScheduleMutation,
  useSetHSScheduleDraftMutation,
} from 'api/apiSlice';
import React, {useEffect, useState} from 'react';

const HSScheduleTable = ({siteId}) => {
  const {data: tableData, isLoading} = useFetchHSSchedulingQuery(siteId);

  const [saveHSSchedule] = useSaveHSScheduleMutation();
  const [setHSScheduleDraft] = useSetHSScheduleDraftMutation();
  const [loadOptimalSchedule] = useLoadOptimalScheduleMutation();

  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [tableOptions, setTableOptions] = useState();

  useEffect(() => {
    if (tableData) {
      setTableOptions(tableData.options);
    }
  }, [tableData]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let newFilters;
    let newSorter;

    if (extra.action === 'sort') {
      newSorter = sorter;
    } else if (extra.action === 'filter') {
      newFilters = filters;
    }

    setFilteredInfo(newFilters || filteredInfo);
    setSortedInfo(newSorter || sorter);
  };

  const diffableRenderFunc = (value, row) => {
    // If we have a new value in the draft, we want to display things differently.
    const different = value.draft !== null && value.final !== value.draft;

    return (
      <>
        {different ? (
          <>
            {value.draft}
            <br />
            Before change: {value.final}
          </>
        ) : (
          value.final
        )}
      </>
    );
  };

  const diffableRenderInputFunc = (value, row, max) => {
    // If we have a new value in the draft, we want to display things differently.
    const different = value.draft !== null && value.final !== value.draft;

    return (
      <>
        <InputNumber
          min={0}
          max={max}
          defaultValue={different ? value.draft : value.final}
          onChange={(newVal) => {
            // We want to update the draft value for this row.
            const idx = tableOptions[row.department].findIndex(
              (obj) =>
                obj.course === row.next_course_number && obj.grade === row.grade
            );
            const departmentOptions = [...tableOptions[row.department]];
            departmentOptions[idx] = {
              ...departmentOptions[idx],
              num_sections: newVal,
            };
            setTableOptions({
              ...tableOptions,
              [row.department]: departmentOptions,
            });
          }}
        />
        {different && (
          <>
            <br />
            Before change: {value.final}
          </>
        )}
      </>
    );
  };

  const getProps = (value, row, field) => {
    const different =
      value[field].draft !== null && value[field].final !== value[field].draft;
    return {
      style: {
        background: different ? '#FFCCCC' : null,
        color: different ? 'black' : null,
      },
    };
  };

  const columns = [
    {
      title: () => <Tooltip title="School">School</Tooltip>,
      dataIndex: 'school',
      key: 'school',
      width: 90,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: 150,
      sorter: (a, b) => a.department.localeCompare(b.department),
      sortOrder: sortedInfo.columnKey === 'department' && sortedInfo.order,
      filters: tableData?.filterOptions,
      filteredValue: filteredInfo.department || null,
      onFilter: (value, record) => record.department.indexOf(value) === 0,
    },
    {
      title: () => (
        <Tooltip title="Number of students enrolled in this grade in the school">
          Students in Grade
        </Tooltip>
      ),
      dataIndex: 'total_in_grade',
      key: 'total_in_grade',
      width: 100,
    },
    {
      title: 'Course',
      dataIndex: 'course_name_display',
      key: 'next_course',
      width: 150,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      key: 'grade',
      width: 80,
    },
    {
      title: () => (
        <Tooltip title="If available, number of Optimal Sections for this AP course, based on AP Pass Prediction data. This may display as 0 if students are allocated into other AP courses in this scenario.">
          Optimal # Sections
        </Tooltip>
      ),
      dataIndex: 'section_count_optimal',
      key: 'optimal_sections',
      width: 80,
    },
    {
      title: '# of Sections',
      dataIndex: 'section_count',
      key: 'section_count',
      width: 150,
      render: (value, row) => diffableRenderInputFunc(value, row, 10),
      onCell: (value, row) => {
        return getProps(value, row, 'section_count');
      },
    },
    {
      title: () => (
        <Tooltip title="Edit this field based on how many students you plan on rostering in this course/section">
          Max # Students per Section
        </Tooltip>
      ),
      dataIndex: 'max_students_per_section',
      key: 'max_students_per_section',
      width: 150,
      render: (value, row) => diffableRenderInputFunc(value, row, 50),
      onCell: (value, row) => {
        return getProps(value, row, 'max_students_per_section');
      },
    },
    {
      title: () => (
        <Tooltip title="Total # of students enrolled in course in current configuration">
          Enrolled in course
        </Tooltip>
      ),
      dataIndex: 'current_enrolled',
      key: 'current_enrolled',
      width: 150,
      render: diffableRenderFunc,
      onCell: (value, row) => {
        return getProps(value, row, 'current_enrolled');
      },
    },
    {
      title: () => (
        <Tooltip title="From PowerSchool - Number of teacher recommendations for this course">
          # Recommended
        </Tooltip>
      ),
      dataIndex: 'num_students_recommended',
      key: 'num_students_recommended',
      width: 150,
    },
    {
      title: () => (
        <Tooltip title="From PowerSchool - Number of course requests for this course">
          # Requests
        </Tooltip>
      ),
      dataIndex: 'num_students_requested',
      key: 'num_students_requested',
      width: 150,
    },
    {
      title: () => (
        <Tooltip title="Number of students who are likely to pass course">
          # Likely to Pass
        </Tooltip>
      ),
      dataIndex: '#likely_to_pass',
      key: 'num_likely_to_pass',
      width: 150,
      render: diffableRenderFunc,
      onCell: (value, row) => {
        return getProps(value, row, '#likely_to_pass');
      },
    },
    {
      title: () => (
        <Tooltip title="Participation rate for this specific AP course based on configuration and total school enrollment">
          Course Projected AP Participation Rate
        </Tooltip>
      ),
      dataIndex: 'projected_participation_rate',
      key: 'participation_rate',
      width: 150,
      render: diffableRenderFunc,
      onCell: (value, row) => {
        return getProps(value, row, 'projected_participation_rate');
      },
    },
    {
      title: () => (
        <Tooltip title="Projected Rate of students who will pass course in current configuration">
          Course Projected Pass Rate
        </Tooltip>
      ),
      dataIndex: 'projected_pass_rate',
      key: 'projected_pass_rat',
      width: 150,
      render: diffableRenderFunc,
      onCell: (value, row) => {
        return getProps(value, row, 'projected_pass_rate');
      },
    },
    {
      title: () => (
        <Tooltip title="Projected AP Participation Rate * Projected AP Pass Rate">
          Projected AP Index
        </Tooltip>
      ),
      dataIndex: 'projected_ap_index',
      key: 'ap_index',
      width: 150,
      render: diffableRenderFunc,
      onCell: (value, row) => {
        return getProps(value, row, 'projected_ap_index');
      },
    },
  ];

  const saveCurrentTableState = () => {
    message.loading({
      content: 'Saving draft...',
      key: 'save',
    });
    setHSScheduleDraft({siteId, schedule: tableOptions})
      .unwrap()
      .then((response) => {
        message.success({
          content: 'Saved draft',
          key: 'save',
          duration: 2,
        });
      });
  };

  const finalizeChanges = () => {
    saveHSSchedule(siteId);
  };

  const loadOptimalIntoDraft = () => {
    message.loading({
      content: 'Loading optimal numbers...',
      key: 'save',
    });
    loadOptimalSchedule(siteId)
      .unwrap()
      .then((response) => {
        message.success({
          content: 'Loaded optimal numbers',
          key: 'save',
          duration: 2,
        });
      });
  };

  return (
    <div>
      <Alert
        style={{marginTop: '10px'}}
        message="AP Index Section Tool"
        description={
          <ul>
            <li>
              The goal of this tool is to be used to maximize your school's AP
              Index - which has consistently been a High School goal.
            </li>
            <li>
              AP Index is a metric tracked across CMOs which can show how our
              schools rank in AP Index on a particular course in comparison to
              other Charter School Organizations.
            </li>
            <li>
              This tool gives you the ability to view different AP section
              scenarios in order to see your school’s predicted AP Index.
            </li>
            <li>
              <b>
                Leaders should use these recommendations as an additional data
                point when making decisions on students’ course enrollments;
                they are not intended to replace school-level decision making.
              </b>
            </li>
            <ul>
              <li>
                The methodology behind these numbers is{' '}
                <a href="https://docs.google.com/document/d/1K3ffm5N0PidcybOo1KY2C5K68RdlGiM7n73i8x5O5KY/edit">
                  in this guide
                </a>
                .
              </li>
              <li>
                As a note, the # Recommended and # Requests columns will be 0
                until this data is entered into PowerSchool.
              </li>
            </ul>
            <li>
              <b>Steps:</b>
            </li>
            <ul>
              <li>
                Edit the “# of sections” and “Max # of Students per Section” to
                see your predicted AP Index for a course.
              </li>
              <li>Click “Load optimal into draft” to revert to original.</li>
              <li>
                Click “Save Draft” to save a version of your current view.
              </li>
              <li>
                Click “Finalize Change” to save these changes on this page.
              </li>
            </ul>
          </ul>
        }
        type="success"
      />
      <div>
        <Table
          pagination={{
            pageSize: 50,
            position: ['bottomRight', 'topRight'],
            total: tableData?.results?.length,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={tableData?.results}
          columns={columns}
          key={tableData}
          size="small"
          loading={isLoading}
          bordered
          onChange={handleTableChange}
          scroll={{
            y: '80vh',
          }}
          rowKey={(record) => {
            return `${record.department}_${record.next_course_number}_${record.grade}`;
          }}
          style={{
            marginBottom: '20px',
          }}
        />

        {/* Add save button */}
        <div className="flex-row">
          <div style={{flex: 1}} />
          <Button
            type="secondary"
            onClick={() => loadOptimalIntoDraft()}
            size="small"
          >
            Load optimal into draft
          </Button>
          <Button
            type="primary"
            onClick={() => saveCurrentTableState()}
            size="small"
          >
            Save draft
          </Button>
          <Button type="primary" onClick={() => finalizeChanges()} size="small">
            Finalize changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HSScheduleTable;
