import {useFetchReportMetaQuery, useFetchReportQuery} from 'api/reportsSlice';
import DataTable from 'components/genericComponents/DataTable';
import useThrottle from 'components/genericComponents/Throttle';
import React, {useEffect, useState} from 'react';

const DataTableDisplay = ({reportId, params, setParams}) => {
  const [queryUrl, setQueryUrl] = useState(reportId ? `${reportId}?` : '');

  const {data: reportMeta, isLoading: loadingMeta} = useFetchReportMetaQuery(
    `${reportId}`,
    {
      skip: !reportId,
    }
  );
  const {
    data: reportData,
    isLoading: loadingData,
    isFetching,
  } = useFetchReportQuery(`${queryUrl}`, {
    skip: !reportId || reportId === 'new',
  });

  const throttledRequest = useThrottle(setQueryUrl);

  useEffect(() => {
    const newQueryUrl = `${reportId}?${params}`;
    if (newQueryUrl !== queryUrl) {
      throttledRequest(newQueryUrl);
    }
  }, [params]);

  return (
    <>
      <h4>{reportMeta?.name}</h4>
      {reportData && (
        <DataTable
          params={params}
          setParams={setParams}
          data={reportData}
          meta={reportMeta}
          loading={loadingMeta || loadingData || isFetching}
          key={reportId + isFetching + loadingData}
          bordered
          size="small"
        />
      )}
    </>
  );
};

export default DataTableDisplay;
