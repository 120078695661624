import StaffRolesTable from 'components/StaffRolesTable';
import React from 'react';

function StaffRolesITSupportPage() {
  return (
    <>
      <h1>Staff Roles IT Support</h1>

      <div width={100}>
        <StaffRolesTable siteId="all" />
        <br />
      </div>
    </>
  );
}

export default StaffRolesITSupportPage;
