import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';

const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    get2fa: builder.query({
      query: () => '/auth/setup-2fa/',
    }),
    verify2fa: builder.mutation({
      query: (data) => ({
        url: `/auth/verify-2fa/`,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) =>
        handleRTKQError(response, {request: {url: '/auth/verify-2fa/'}}),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/auth/login/`,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) =>
        handleRTKQError(response, {request: {url: '/auth/login/'}}),
    }),
  }),
});

export const {useGet2faQuery, useVerify2faMutation, useLoginMutation} = authApi;
