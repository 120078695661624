import {Table} from 'antd';
import {useFetchAuditRulesQuery} from 'api/auditsSlice';
import React from 'react';

const AuditRulesTable = () => {
  const {data, isLoading} = useFetchAuditRulesQuery();

  const columns = [
    {
      title: 'Error Type',
      dataIndex: 'error_type',
      key: 'error_type',
    },
    {
      title: 'Error Description',
      dataIndex: 'error_description',
      key: 'error_description',
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'States',
      dataIndex: 'states',
      key: 'states',
    },
    {
      title: 'Target Table',
      dataIndex: 'target_table',
      key: 'target_table',
    },
    {
      title: 'Field Value',
      dataIndex: 'field_value',
      key: 'field_value',
    },
    {
      title: 'Zendesk URL',
      dataIndex: 'zendesk_url',
      key: 'zendesk_url',
      render: (text) => <a href={text}>{text}</a>,
    },
    {
      title: 'Enable Notes',
      dataIndex: 'enable_notes',
      key: 'enable_notes',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      key={data}
      pagination={false}
      size="small"
      rowKey={(record) => JSON.stringify(record)}
      loading={isLoading}
      bordered
      title={(currentPageData) => (
        <div>Displayed rules: {currentPageData.length}</div>
      )}
      scroll={{x: '100%'}}
    />
  );
};

export default AuditRulesTable;
