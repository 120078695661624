import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';
import {uniqBy} from 'lodash';

const seedsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSeeds: builder.query({
      query: () => `/seeds/seed_tree`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['Seeds'],
    }),
    fetchSeedData: builder.query({
      query: (data) => `/seeds/seed/${data}/`,
      transformResponse: (response) => {
        // sort columns by index
        const sortedColumns = response.metadata?.columns?.sort(
          (a, b) => a.index - b.index
        );
        return {
          ...response,
          metadata: {
            ...response.metadata,
            columns: sortedColumns,
          },
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, id) => [{type: 'SeedData', id}],
    }),
    fetchColumnOptions: builder.query({
      query: (data) => `/seeds/column_options/?${data}`,
      transformResponse: (response) => {
        const deduped = {};
        // deduplicate stringified options for each source table
        for (const [key, value] of Object.entries(response.options)) {
          deduped[key] = uniqBy(value, JSON.stringify);
        }
        return deduped;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ColumnOptions'],
    }),
    fetchAutocomplete: builder.query({
      query: ({table, data}) => `/seeds/column_options/${table}/?${data}`,
      transformResponse: (response) => {
        return response.options;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ColumnOptions'],
    }),
    fetchSeedTags: builder.query({
      query: () => `/seeds/tag/`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['SeedTags'],
    }),
    fetchSeedCategories: builder.query({
      query: () => `/seeds/seed_category_tree`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['SeedCategories'],
    }),
    setSeedData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        {type: 'SeedData', id: data.seed_name.replace('_draft', '')},
        'Seeds',
      ],
    }),
    setSeedMetaData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/${data.seed_name}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, data) => [
        {type: 'SeedData', id: data.seed_name.replace('_draft', '')},
        'Seeds',
      ],
    }),
    createOrUpdateSeedTag: builder.mutation({
      query: (tag) => ({
        url: tag.id ? `/seeds/tag/${tag.id}/` : `/seeds/tag/`,
        method: tag.id ? 'PUT' : 'POST',
        body: tag,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['Seeds', 'SeedTags', 'SeedCategories'],
    }),
    deleteSeedTag: builder.mutation({
      query: (tag_id) => ({
        url: `/seeds/tag/${tag_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['Seeds', 'SeedTags', 'SeedCategories'],
    }),
    createOrUpdateSeedCategory: builder.mutation({
      query: (category) => ({
        url: category.id
          ? `/seeds/category/${category.id}/`
          : `/seeds/category/`,
        method: category.id ? 'PUT' : 'POST',
        body: category,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['Seeds', 'SeedTags', 'SeedCategories'],
    }),
    deleteSeedCategory: builder.mutation({
      query: (category_id) => ({
        url: `/seeds/category/${category_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['Seeds', 'SeedTags', 'SeedCategories'],
    }),
  }),
});

export const {
  useFetchSeedsQuery,
  useFetchSeedDataQuery,
  useFetchColumnOptionsQuery,
  useFetchAutocompleteQuery,
  useFetchSeedTagsQuery,
  useFetchSeedCategoriesQuery,
  useSetSeedDataMutation,
  useSetSeedMetaDataMutation,
  useCreateOrUpdateSeedTagMutation,
  useDeleteSeedTagMutation,
  useCreateOrUpdateSeedCategoryMutation,
  useDeleteSeedCategoryMutation,
} = seedsSlice;
