import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, message} from 'antd';
import Papa from 'papaparse';
import React from 'react';

const ImportFile = ({
  type,
  accept,
  fileList,
  setData,
  setFiles,
  title = 'Select a File',
}) => {
  const handleInputChange = (e) => {
    setData([]);
    setFiles([]);
    if (!e) {
      message.error({content: 'No file selected', duration: 10});
      return false;
    }
    Papa.parse(e, {
      header: true,
      newline: '\n',
      delimiter: ',',
      skipEmptyLines: 'greedy',
      transformHeader: (header) => {
        return header.replace(/\r/g, '').trim();
      },
      transform: (value) => {
        return value.replace(/\r/g, '').trim();
      },
      complete: (results) => {
        validateData(results);
      },
    });
  };

  const validateData = (results) => {
    if (!results.data) {
      message.error({
        content: 'No data found in CSV file',
        duration: 10,
      });
      return false;
    } else if (results.errors.length) {
      message.error({
        content: 'CSV file contains errors',
        duration: 10,
      });
      return false;
    } else {
      setData(results.data);
      setFiles(results.files);
    }
  };

  return (
    <Upload
      type={type}
      accept={accept}
      fileList={fileList}
      beforeUpload={(e) => handleInputChange(e)}
    >
      <Button icon={<UploadOutlined />}>{title}</Button>
    </Upload>
  );
};

export default ImportFile;
