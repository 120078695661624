import {PlusCircleOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {generateUniqueId} from 'Utils';
import {
  Alert,
  Button,
  Divider,
  Drawer,
  Popconfirm,
  Spin,
  Tooltip,
  message,
} from 'antd';
import {
  useDeleteAggregateDataReportMutation,
  useFetchAggregateReportsListQuery,
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
  useFetchSingleAggregateReportQuery,
  useSaveAggregateDataReportDraftMutation,
  useSaveAggregateDataReportMutation,
} from 'api/reportsSlice';
import ColumnForm from 'components/dataReports/ColumnForm';
import FilterForm from 'components/dataReports/FilterForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import useThrottle from 'components/genericComponents/Throttle';
import {handleApiError} from 'errorHandler';
import {isEqual} from 'lodash';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataReportEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reportId = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [sourceReportId, setSourceReportId] = useState();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [localReport, setLocalReport] = useState({
    draft_metadata: {
      columns: [],
      filters: [],
    },
  });

  const {data: reportData, isLoading} = useFetchSingleAggregateReportQuery(
    reportId,
    {
      skip: !reportId || reportId === 'new',
    }
  );
  const {data: sources} = useFetchReportSourcesQuery();
  const {data: columns, isLoading: loadingColumns} =
    useFetchReportSourceColumnsQuery(sourceReportId, {
      skip: !sourceReportId,
    });
  const {data: aggregateReportsData} = useFetchAggregateReportsListQuery();

  const [saveAggregateDataReportDraft] =
    useSaveAggregateDataReportDraftMutation();
  const [saveAggregateDataReport] = useSaveAggregateDataReportMutation();
  const [deleteAggregateDataReport] = useDeleteAggregateDataReportMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    if (reportData?.source) {
      setSourceReportId(reportData.source);
    }
  }, [reportData?.source]);

  useEffect(() => {
    if (reportData) {
      const newLocalReport = {
        ...reportData,
        draft_metadata: {
          ...reportData.draft_metadata,
          columns: reportData.draft_metadata?.columns?.map((column) => ({
            ...column,
            renderVisible:
              localReport?.draft_metadata?.columns?.find(
                (c) => c.id === column.id
              )?.renderVisible || false,
          })),
          filters: reportData.draft_metadata?.filters?.map((filter) => ({
            ...filter,
            renderVisible:
              localReport?.draft_metadata?.filters?.find(
                (f) => f.id === filter.id
              )?.renderVisible || false,
          })),
        },
      };
      setLocalReport(newLocalReport);
    }
  }, [reportData?.id]);

  useEffect(() => {
    setSaveDisabled(
      !(
        reportData &&
        !reportData.errors?.length &&
        reportData.source &&
        reportData.report_name &&
        reportData.draft_metadata &&
        reportData.draft_metadata.columns?.length > 0
      )
    );
  }, [reportData]);

  const handleColumnOrderChanged = (result) => {
    if (!result.destination) return;
    const temp = [...localReport?.draft_metadata?.columns];
    const [reorderedItem] = temp.splice(result.source.index, 1);
    temp.splice(result.destination.index, 0, reorderedItem);
    handleReportDataChanged(
      {
        filters: localReport?.draft_metadata?.filters || [],
        columns: temp,
      },
      'draft_metadata'
    );
  };

  const saveReportDraftCallback = (report) => {
    const metadata = {
      columns: report.draft_metadata?.columns?.filter((column) =>
        column.column_type === 'DIMENSION'
          ? column?.source_column
          : column?.source_column
      ),
      filters: report.draft_metadata?.filters?.filter(
        (filter) => filter?.source_column
      ),
    };
    metadata?.columns?.forEach((column) => {
      delete column?.renderVisible;
    });
    metadata?.filters?.forEach((filter) => {
      delete filter?.renderVisible;
    });
    // don't save if nothing has changed or if essential data is missing
    if (
      isEqual(report, reportData) &&
      isEqual(metadata, reportData.draft_metadata)
    )
      return;
    if (!report.source) return;

    saveAggregateDataReportDraft(report)
      .unwrap()
      .then((response) => {
        if (reportId === 'new') navigate(`/data_report/${response.id}/editor`);
        setLocalReport(response);
        message.success({
          content: 'Auto-saved draft',
          duration: 2,
          key: 'saveReport',
        });
      })
      .catch((error) => {
        error.data ??= {};
        error.data.detail = 'Error saving draft';
        handleApiError(error);
      });
  };

  const throttledRequest = useThrottle(saveReportDraftCallback);

  const handleReportDataChanged = (value, type, save = true) => {
    const temp = {
      ...localReport,
      [type]: value ?? null,
    };
    if (type === 'source') {
      if (!value) {
        setSourceReportId(null);
      } else {
        if (!temp.report_name) {
          temp.report_name = value + ' Report';
        }
        setSourceReportId(value);
      }
    }
    setLocalReport(temp);
    if (save) throttledRequest(temp);
  };

  const handleColumnChanged = (value, column, field, idx) => {
    const reportColummns = localReport?.draft_metadata?.columns || [];
    let temp = [...reportColummns];
    let save = true;
    if (!temp[idx]) {
      temp.push({
        renderVisible: true,
        id: generateUniqueId(temp.map((column) => column.id)),
        column_type: 'DIMENSION',
        filter_type: 'FREE_TEXT',
      });
      save = false;
    } else if (!column.column_type) {
      temp[idx] = {
        ...temp[idx],
        [field]: value,
      };
    } else if (column.column_type === 'DIMENSION') {
      temp[idx] = handleDimensionChanged(value, field, temp[idx]);
    } else {
      temp[idx] = handleMetricChanged(value, field, temp[idx]);
    }
    if (field === 'renderVisible') {
      save = false;
    }
    handleReportDataChanged(
      {
        ...localReport?.draft_metadata,
        columns: temp,
      },
      'draft_metadata',
      save
    );
  };

  const handleDimensionChanged = (value, field, dimension) => {
    let changedDimension = {
      ...dimension,
      id:
        dimension.id ??
        generateUniqueId(localReport?.draft_metadata?.columns.map((c) => c.id)),
      [field]: value,
    };
    if (field === 'source_column') {
      changedDimension = handleColumnSelected(value, changedDimension);
    }
    return changedDimension;
  };

  const handleMetricChanged = (value, field, metric) => {
    const changedMetric = {
      ...metric,
      id:
        metric.id ??
        generateUniqueId(localReport?.draft_metadata?.columns.map((c) => c.id)),
      [field]: value,
    };
    if (field === 'source_column' || field === 'metric') {
      changedMetric.alias = autogenerateMetricColumnName(changedMetric);
    }
    if (field === 'source_column') {
      changedMetric.source_data_type = columns.find(
        (column) => column.column_name === value
      )?.data_type;
    }
    return changedMetric;
  };

  const handleFilterChanged = (value, idx, field) => {
    const filters = localReport?.draft_metadata?.filters || [];
    const temp = [...filters];
    let save = true;
    if (temp[idx]) {
      let changedFilter = {
        ...temp[idx],
        id:
          temp[idx].id ??
          generateUniqueId(
            localReport?.draft_metadata?.filters.map((f) => f.id)
          ),
        [field]: value,
      };
      if (field === 'source_column') {
        changedFilter = handleColumnSelected(value, changedFilter);
      }
      temp[idx] = changedFilter;
    } else {
      temp.push({
        renderVisible: true,
        id: generateUniqueId(temp.map((column) => column.id)),
        filter_type: 'FREE_TEXT',
      });
      save = false;
    }
    if (field === 'renderVisible') {
      save = false;
    }
    handleReportDataChanged(
      {
        ...localReport?.draft_metadata,
        filters: temp,
      },
      'draft_metadata',
      save
    );
  };

  const handleColumnSelected = (value, changedElement) => {
    if (!value) return;
    const temp = {
      ...changedElement,
      source_column: value,
      data_type: columns.find((column) => column.column_name === value)
        ?.data_type,
      alias: value,
    };
    return temp;
  };

  const autogenerateMetricColumnName = (metric) => {
    const c = metric.source_column?.toLowerCase().replace(' ', '_') ?? '';
    const m = metric.metric?.toLowerCase().replace(' ', '_') ?? '';
    return `${c}_${m}`;
  };

  const handleSave = () => {
    saveAggregateDataReport(reportData.id).then((response) => {
      message.success({
        content: 'Saved report',
        duration: 2,
        key: 'saveReport',
      });
    });
  };

  const handleDelete = (type, idx) => {
    const temp = localReport?.draft_metadata[type]
      ? [...localReport?.draft_metadata[type]]
      : [];
    temp.splice(idx, 1);
    handleReportDataChanged(
      {
        ...localReport?.draft_metadata,
        [type]: temp,
      },
      'draft_metadata'
    );
  };

  const discardChanges = () => {
    const metadata = reportData?.metadata || {};
    handleReportDataChanged(metadata, 'draft_metadata');
  };

  const deleteReport = () => {
    deleteAggregateDataReport(reportData?.id).then(() => {
      message.success({
        content: 'Deleted report',
        duration: 2,
        key: 'saveReport',
      });
      navigate('/data_report_editor');
    });
  };

  const handleAddColumnsFromSource = () => {
    const temp = columns.map((column) => ({
      column_type: 'DIMENSION',
      source_column: column.column_name,
      data_type: column.data_type,
      alias: column.column_name,
      renderVisible: false,
      filter_type: 'FREE_TEXT',
      id: generateUniqueId(
        localReport?.draft_metadata?.columns.map((c) => c.id)
      ),
    }));
    handleReportDataChanged(
      {
        ...localReport?.draft_metadata,
        columns: temp,
      },
      'draft_metadata'
    );
  };

  return (
    <>
      <Drawer
        title="Data Report Editor"
        placement="left"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={600}
        getContainer={false}
        style={{
          position: 'fixed',
          overflowY: 'auto',
          minHeight: 'calc(100% - 64px)',
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div style={{overflowY: 'auto'}}>
          <Spin spinning={isLoading}>
            <h4>Report Metadata</h4>
            {reportData?.errors?.length ? (
              <Alert
                type="error"
                description={
                  <div>
                    {reportData.errors.map((error) => (
                      <div key={error}>* {error}</div>
                    ))}
                  </div>
                }
              />
            ) : null}
            <div className="flex-column">
              <div
                className="flex-row"
                style={{justifyContent: 'space-between'}}
              >
                <div style={{flexGrow: 1}}>
                  <SelectOptions
                    label="Report Source"
                    required={true}
                    options={sources}
                    selectedOption={localReport?.source}
                    changeSelection={(value) =>
                      handleReportDataChanged(value, 'source')
                    }
                    style={{width: '100%'}}
                  />
                </div>
                {localReport?.source &&
                  columns?.length &&
                  (!localReport?.draft_metadata?.columns?.length ||
                    localReport?.draft_metadata?.columns.filter((c) => c.errors)
                      .length === 0) && (
                    <Button onClick={handleAddColumnsFromSource} size="large">
                      Load All Columns
                    </Button>
                  )}
              </div>
              <SelectOptions
                label="Report Folder"
                options={aggregateReportsData?.categories}
                selectedOption={localReport?.folder}
                changeSelection={(value) =>
                  handleReportDataChanged(value, 'folder')
                }
                placeholder="Select a folder"
                style={{width: '100%'}}
                onAdd={(value) => handleReportDataChanged(value, 'folder')}
              />

              {/* name of new report */}
              <InputField
                label="Name"
                required={true}
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'report_name')
                }
                value={localReport?.report_name}
                style={{width: '100%'}}
              />
              {/* description */}
              <InputField
                label="Description"
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'short_description')
                }
                value={localReport?.short_description}
                multiline={true}
                style={{width: '100%'}}
              />
              <InputField
                label="Detailed Description"
                onChange={(e) =>
                  handleReportDataChanged(e.target.value, 'long_description')
                }
                value={localReport?.long_description}
                multiline={true}
                style={{width: '100%'}}
              />
            </div>
            <Divider />

            <Alert
              description={
                <ul>
                  <li>
                    Drag and drop columns to change their order in the report.
                  </li>
                  <li>
                    Frozen columns will always be displayed at the left of the
                    table.
                  </li>
                  <li>
                    The sort priority follows the order of columns in this list,
                    with the first column having the highest priority. Frozen
                    columns' sort priorities are also determined by their
                    position in this list, even though they’re displayed on the
                    left side of the table.
                  </li>
                  <li>
                    Filters defined in the <b>Filters</b> section below will be
                    external the table.
                  </li>
                </ul>
              }
              type="info"
              style={{marginBottom: '20px'}}
            />
            {
              <Spin spinning={loadingColumns}>
                {columns?.length > 0 && (
                  <>
                    <div className="flex-row">
                      {/* list of columns */}
                      <div
                        style={{
                          flex: '1 1 300px',
                        }}
                      >
                        <h4>Columns</h4>
                        <DragDropContext onDragEnd={handleColumnOrderChanged}>
                          <Droppable droppableId="1">
                            {(provided) => (
                              <ColumnList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {localReport?.draft_metadata?.columns?.map(
                                  (column, idx) => (
                                    <ColumnForm
                                      key={column?.id}
                                      columns={columns}
                                      column={column}
                                      setColumn={(value, field) =>
                                        handleColumnChanged(
                                          value,
                                          column,
                                          field,
                                          idx
                                        )
                                      }
                                      handleDelete={() =>
                                        handleDelete('columns', idx)
                                      }
                                      renderVisible={column?.renderVisible}
                                      idx={idx}
                                    />
                                  )
                                )}
                                {provided.placeholder}
                              </ColumnList>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <Button
                          style={{width: '40%', margin: '5px 0'}}
                          onClick={handleColumnChanged}
                        >
                          <PlusCircleOutlined />
                          Add Column
                        </Button>
                      </div>
                    </div>
                    <Divider />
                    <div>
                      <div
                        style={{
                          flex: '1 1 300px',
                        }}
                      >
                        {/* list of filters */}
                        <h4>Filters</h4>

                        {localReport?.draft_metadata?.filters?.map(
                          (filter, idx) => (
                            <FilterForm
                              key={filter?.id}
                              columns={columns}
                              filter={filter}
                              setFilter={(value, field) =>
                                handleFilterChanged(value, idx, field)
                              }
                              handleDelete={() => handleDelete('filters', idx)}
                              renderVisible={filter?.renderVisible}
                            />
                          )
                        )}
                        <Button
                          style={{width: '40%'}}
                          onClick={handleFilterChanged}
                        >
                          <PlusCircleOutlined />
                          Add Filter
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Spin>
            }
            <Divider />
            <Tooltip
              title={
                saveDisabled ? 'Please fix errors before saving' : 'Save Report'
              }
              placement="right"
            >
              <Button
                style={{margin: '10px 0'}}
                type="primary"
                onClick={handleSave}
                disabled={saveDisabled}
              >
                Save Report
              </Button>
            </Tooltip>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to discard changes to this report?
                  <br />
                  The report will revert to the last saved state.
                  <br />
                  This cannot be undone.
                </div>
              }
              onConfirm={discardChanges}
              okText="Yes"
              cancelText="No"
              okButtonProps={{danger: true, type: 'default'}}
              cancelButtonProps={{type: 'primary'}}
            >
              <Button style={{margin: '10px'}}>Discard Changes</Button>
            </Popconfirm>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to delete this report?
                  <br />
                  This cannot be undone.
                </div>
              }
              onConfirm={deleteReport}
              okText="Yes"
              cancelText="No"
              okButtonProps={{danger: true, type: 'default'}}
              cancelButtonProps={{type: 'primary'}}
            >
              <Button danger style={{margin: '10px 0'}}>
                Delete Report
              </Button>
            </Popconfirm>
          </Spin>
        </div>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        path={`/data_report/${reportId}`}
        key={reportId + editorMode}
      />
    </>
  );
};

export default DataReportEditor;
