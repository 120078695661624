import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';

const extractCategories = (response) => {
  const categoriesWithDuplicates = response.map((e) => e.folder) || [];
  const categories = [...new Set(categoriesWithDuplicates)]
    .filter((e) => !!e)
    .sort((a, b) => a.localeCompare(b));
  categories.push('Assorted');
  const reportsByCategory = categories.reduce((acc, category) => {
    acc[category] = [];
    return acc;
  }, {});
  for (const report of response) {
    if (!report.folder) {
      reportsByCategory['Assorted'].push(report);
    } else {
      reportsByCategory[report.folder].push(report);
    }
  }
  for (const category in reportsByCategory) {
    if (reportsByCategory[category].length) {
      reportsByCategory[category].sort((a, b) =>
        a.report_name.localeCompare(b.report_name)
      );
    } else {
      delete reportsByCategory[category];
    }
  }
  const savedReports = {};
  Object.keys(reportsByCategory).forEach((f) => {
    const saved = reportsByCategory[f].filter((r) => r.saved);
    if (saved.length) {
      savedReports[f] = saved;
    }
  });

  const savedReportsList = response
    .filter((r) => r.saved)
    .map((r) => ({
      value: r.id,
      label: `${r.folder ?? ''}/${r.report_name}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    categories,
    reportsByCategory,
    savedReports,
    savedReportsList,
  };
};

const reportsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSingleAggregateReport: builder.query({
      query: (report_id) => `/data_reports/metadata/${report_id}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {
          type: 'SingleAggregateReport',
          id: arg ? arg.toString()?.split('?')[0] : arg,
        },
      ],
    }),
    fetchReport: builder.query({
      query: (report_id) => `/data_reports/report/${report_id}`,
      transformResponse: (response) => {
        return {
          error: response.errors,
          report: response.report,
          sql: response.sql_statement,
          total: response.row_count,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'SingleAggregateReport', id: arg},
      ],
    }),
    fetchReportMeta: builder.query({
      query: (queryUrl) => `/data_reports/report_meta/${queryUrl}`,
      transformResponse: (response) => {
        const newColumns = response?.columns?.map((column) => ({
          ...column,
          column_type: column.data_type,
          column_name: column.alias,
        }));
        return {
          ...response,
          columns: newColumns,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {
          type: 'AggregateReportMeta',
          id: arg.toString().indexOf('?') > -1 ? arg.split('?')[0] : arg,
        },
      ],
    }),
    fetchAggregateReportsList: builder.query({
      query: () => `/data_reports/reports`,
      transformResponse: (response) => {
        return extractCategories(response);
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['AggregateReportsList'],
    }),
    fetchReportSources: builder.query({
      query: () => `/data_reports/sources`,
      transformResponse: (response) => {
        return response.sources?.sort();
      },
      transformErrorResponse: handleRTKQError,
      providesTags: ['ReportSources'],
    }),
    fetchReportSourceColumns: builder.query({
      query: (source) => `/data_reports/sources/${source}`,
      transformResponse: (response) => {
        return response.columns;
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'ReportSourceColumns', id: arg},
      ],
    }),
    saveAggregateDataReportDraft: builder.mutation({
      query: (report) => ({
        url: `/data_reports/editor/draft`,
        method: 'PUT',
        body: report,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        if (!arg.id) {
          return [{type: 'AggregateReportsList'}];
        } else {
          return [
            {type: 'SingleAggregateReport', id: arg.id},
            {type: 'AggregateReportMeta', id: arg.id},
            'AggregateReportsList',
          ];
        }
      },
    }),
    saveAggregateDataReport: builder.mutation({
      query: (report_id) => ({
        url: `/data_reports/editor/publish/${report_id}`,
        method: 'PUT',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'SingleAggregateReport', id: arg},
          {type: 'AggregateReportMeta', id: arg},
          'AggregateReportsList',
        ];
      },
    }),
    deleteAggregateDataReport: builder.mutation({
      query: (report_id) => ({
        url: `/data_reports/editor/delete/${report_id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => {
        return [
          {type: 'SingleAggregateReport', id: arg},
          {type: 'AggregateReportMeta', id: arg},
          'AggregateReportsList',
        ];
      },
    }),
  }),
});

export const {
  useFetchSingleAggregateReportQuery,
  useFetchReportQuery,
  useFetchReportMetaQuery,
  useFetchAggregateReportsListQuery,
  useFetchReportSourcesQuery,
  useFetchReportSourceColumnsQuery,
  useSaveAggregateDataReportDraftMutation,
  useSaveAggregateDataReportMutation,
  useDeleteAggregateDataReportMutation,
} = reportsSlice;
