import {ResponsiveBar} from '@nivo/bar';
import {Spin} from 'antd';
import {useFetchTaskCompletionStatsQuery} from 'api/esoySlice';
import React, {useEffect, useState} from 'react';

const barColors = {
  setup_homerooms: 'hsl(122, 85%, 79%)',
  setup_homerooms_complete: '#7B927C',
  assign_homerooms: 'hsl(178, 83%, 77%)',
  assign_homerooms_complete: '#6F9998',
  setup_teachers: 'hsl(0, 84%, 81%)',
  setup_teachers_complete: '#A38282',
};

const getColor = (bar) => {
  return barColors[bar.id];
};

function ESoyTaskStatusBarGraph({data, keys}) {
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, data.length * 20);
  }, [data]);

  const getBorder = (d) => {
    let task = d.data.id;
    let school_name = d.data.indexValue;
    let school = data.find((s) => s.school_name === school_name);
    if (school && school[`${task}_complete`]) {
      return barColors[task + '_complete'];
    } else {
      return barColors[task];
    }
  };
  return (
    <Spin spinning={spinning}>
      <div style={{height: '320px'}}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy="school_name"
          minValue={0}
          maxValue={100}
          margin={{top: 40, right: 10, bottom: 70, left: 30}}
          padding={0.3}
          innerPadding={1}
          layout="vertical"
          valueScale={{type: 'linear'}}
          indexScale={{type: 'band', round: true}}
          colors={getColor}
          height={300}
          borderColor={(d) => getBorder(d)}
          borderWidth={1}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          enableLabel={false}
          tooltip={(data) => {
            return (
              <div
                style={{
                  backgroundColor: 'rgba(221, 221, 221, 0.8)',
                  padding: '5px',
                }}
              >
                {data.id}
                <br />
                {data.indexValue}:&nbsp;
                <strong>{data.value}</strong>
                {data.data[`${data.id}_complete`] && <div>Completed</div>}
              </div>
            );
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -30,
              itemsSpacing: 20,
              itemWidth: 120,
              itemHeight: 10,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          groupMode="grouped"
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </Spin>
  );
}

function EsoyTaskCompletionGraph({dataSource}) {
  const {data} = useFetchTaskCompletionStatsQuery();
  const [filteredGraphData, setFilteredGraphData] = useState([]);
  const graphKeys = ['setup_homerooms', 'assign_homerooms', 'setup_teachers'];

  useEffect(() => {
    if (data && dataSource) {
      filterData(data);
    }
  }, [data]);

  const filterData = (data) => {
    const schools = [];
    dataSource.forEach((school) => {
      const schoolData = data.find((item) => item.site_id === school.site_id);
      const newSchool = {
        site_id: school.site_id,
        school_name: school.school,
        setup_homerooms: schoolData ? schoolData.setup_homerooms : null,
        assign_homerooms: schoolData ? schoolData.assign_homerooms : null,
        setup_teachers: schoolData ? schoolData.setup_teachers : null,
        setup_homerooms_complete: school.setup_homerooms
          ? school.setup_homerooms.completed
          : null,
        assign_homerooms_complete: school.assign_homerooms
          ? school.assign_homerooms.completed
          : null,
        setup_teachers_complete: school.setup_teachers
          ? school.setup_teachers.completed
          : null,
      };
      schools.push(newSchool);
    });

    // Sort stats by school id.
    function cmp(a, b) {
      if (b.school_name === null) return -1;
      if (a.school_name > b.school_name) return 1;
      if (a.school_name < b.school_name) return -1;
      return 0;
    }
    schools.sort(cmp);
    setFilteredGraphData(schools);
  };

  return <ESoyTaskStatusBarGraph data={filteredGraphData} keys={graphKeys} />;
}

export default EsoyTaskCompletionGraph;
