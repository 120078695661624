import {Button, Comment, Popconfirm, Tag, message} from 'antd';
import {useDeletePageMutation, useUpdatePageMutation} from 'api/cmsSlice';
import moment from 'moment';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const PageVersionItem = ({version, currentVersion, publishedVersion}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';

  const [deletePage] = useDeletePageMutation();
  const [updatePage] = useUpdatePageMutation();

  const handleDelete = (version) => {
    if (!pageSlug) {
      message.error('Cannot delete a new page');
    }
    if (pageSlug) {
      deletePage({
        page_slug: pageSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success('Page deleted successfully');
        });
    }
    if (!version) navigate(`/data_portal/editor`);
  };

  const handlePublish = (version) => {
    if (!pageSlug) {
      message.error('Cannot publish a new page');
    }
    if (pageSlug) {
      updatePage({
        slug: pageSlug,
        version,
        published: true,
      })
        .unwrap()
        .then(() => {
          message.success('Page published successfully');
        });
    }
  };

  return (
    <Comment
      style={{
        borderTop: '1px solid #f0f0f0',
      }}
      actions={[
        <Button
          type="link"
          size="small"
          key="edit"
          disabled={version.version === currentVersion}
          onClick={() =>
            navigate(
              `/data_portal/${version.slug}/editor?version=${version.version}`
            )
          }
        >
          View
        </Button>,
        <Popconfirm
          title="Are you sure you want to delete this version?"
          onConfirm={() => handleDelete(version.version)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" type="link" danger>
            Delete
          </Button>
        </Popconfirm>,
        <Popconfirm
          title="Are you sure you want to publish this version?"
          onConfirm={() => handlePublish(version.version)}
          okText="Yes"
          disabled={version.version === publishedVersion}
          cancelText="No"
        >
          <Button
            disabled={version.version === publishedVersion}
            size="small"
            type="link"
          >
            Publish this Version
          </Button>
        </Popconfirm>,
      ]}
      key={version.version}
      author={version.author ?? 'Unknown'}
      avatar={null}
      content={
        <div>
          <strong>{version.title}</strong>
          {version.version === currentVersion && (
            <Tag color="blue" style={{marginLeft: 10}}>
              Current
            </Tag>
          )}
          {version.version === publishedVersion && (
            <Tag color="green" style={{marginLeft: 10}}>
              Published
            </Tag>
          )}
        </div>
      }
      datetime={moment(version.updated).format('MMMM Do YYYY, h:mm a')}
    />
  );
};

export default PageVersionItem;
