import {retryGet, retryPost} from 'api/axiosFuncs';

export const fetchSchoolHomeroomAssignments = (siteId, rolloverMode) => {
  return retryGet(
    `/esoy/school_homerooms_assignment/${siteId}?rollover_mode=${rolloverMode}`
  );
};

export const shuffleGradeHomeroomAssignments = (
  siteId,
  grade,
  rolloverMode
) => {
  return retryPost(
    `/esoy/school_homerooms_assignment_shuffle/${siteId}/${grade}?rollover_mode=${rolloverMode}`,
    null
  );
};

export const setSchoolHomeroomAssignments = (siteId, assignments) => {
  return retryPost(`/esoy/school_homerooms_assignment/${siteId}`, assignments);
};

export const cleanupHomeroomAssignments = (siteId) => {
  return retryPost(
    `/esoy/school_homerooms_assignment_cleanup/${siteId}?rollover_mode=pre`,
    null
  );
};
