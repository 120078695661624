import {LoadingOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, message} from 'antd';
import {useMapPersonUcpidMutation} from 'api/apiSlice';
import React, {useState} from 'react';

const PersonMapping = () => {
  const [primaryUcpid, setPrimaryUcpid] = useState('');
  const [ucpidToMerge, setUcpidToMerge] = useState('');

  const [mapPersonUcpid, {isLoading}] = useMapPersonUcpidMutation();

  const handleInputChangePrimary = (e) => {
    setPrimaryUcpid(e.target.value);
  };

  const handleInputChangeMerge = (e) => {
    setUcpidToMerge(e.target.value);
  };

  const mergeUcpids = () => {
    const key = 'save';
    message.loading({
      content: 'Merging UCPIDs...',
      key,
      duration: 0,
    });
    mapPersonUcpid({
      primaryUcpid,
      ucpidToMerge,
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          message.error({
            content: response.error,
            key,
            duration: 2,
          });
        } else {
          message.success({
            content: 'Merged UCPIDs successfully',
            key,
            duration: 2,
          });
          // Reset the UCPID inputs
          setPrimaryUcpid('');
          setUcpidToMerge('');
        }
      });
  };

  return (
    <Card
      style={{
        margin: '20px 0',
      }}
    >
      <Form>
        <h3>Merge one UCPID</h3>
        <Input
          size="large"
          placeholder="primary ucpid..."
          prefix={<UserOutlined />}
          value={primaryUcpid}
          onChange={(e) => handleInputChangePrimary(e)}
          style={{
            marginBottom: '10px',
          }}
        />
        <Input
          size="large"
          placeholder="ucpid to merge..."
          prefix={<UserOutlined />}
          value={ucpidToMerge}
          onChange={(e) => handleInputChangeMerge(e)}
          style={{
            marginBottom: '10px',
          }}
        />

        <Button
          onClick={() => {
            mergeUcpids();
          }}
          disabled={primaryUcpid === '' || ucpidToMerge === ''}
        >
          {isLoading ? <LoadingOutlined /> : 'Merge UCPIDs'}
        </Button>
      </Form>
    </Card>
  );
};

export default PersonMapping;
