import {ExportOutlined} from '@ant-design/icons';
import {Collapse} from 'antd';
import React from 'react';

const {Panel} = Collapse;

const Artifacts = ({artifacts = []}) => {
  return (
    <Collapse
      accordion
      style={{
        borderBottom: '1px solid #d9d9d9',
        overflowY: 'auto',
        maxHeight: '79vh',
      }}
    >
      {artifacts.map((artifact, index) => (
        <Panel
          header={artifact.name}
          key={index}
          extra={
            <a
              href={artifact.source}
              target="_blank"
              rel="noreferrer"
              style={{marginLeft: '10px'}}
            >
              <ExportOutlined />
            </a>
          }
        >
          <iframe
            key={artifact.id}
            src={artifact.source}
            width="500"
            height="500"
            frameBorder="0"
          />
        </Panel>
      ))}
    </Collapse>
  );
};

export default Artifacts;
