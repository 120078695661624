import Auth from 'Auth';
import {Alert, Breadcrumb, Button} from 'antd';
import {useFetchSingleAggregateReportQuery} from 'api/reportsSlice';
import DataReport from 'components/dataReports/DataReport';
import DataReportEditor from 'components/dataReports/DataReportEditor';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const DataReportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reportId = location.pathname.split('/')[2];
  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [errors, setErrors] = useState([]);

  const {data, isLoading} = useFetchSingleAggregateReportQuery(reportId, {
    skip: !reportId || reportId === 'new',
  });

  useEffect(() => {
    if ((!reportId || reportId === 'new') && data && editorMode) {
      navigate(`/data_report/${data.id}/editor`);
    }
  }, [data]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/data_report/${editorMode ? 'editor' : ''}`}>
            Data Reports
          </Link>
        </Breadcrumb.Item>
        {data?.metadata?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          {data?.report_name ? (
            <Link to={`/data_report/${reportId}`}>{data?.report_name}</Link>
          ) : (
            'New'
          )}
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <div className="flex-column">
        <DataReportEditor
          errors={errors}
          setErrors={setErrors}
          loading={isLoading}
        />
        {!data && (
          <Alert
            message={
              <div>
                Please define a report in the editor.
                <Button
                  type="primary"
                  onClick={() => setDrawerVisible(true)}
                  style={{display: 'inline-block', marginLeft: 10}}
                >
                  Open Editor
                </Button>
              </div>
            }
            type="info"
            style={{margin: 10}}
          />
        )}
        <DataReport loading={isLoading} />
      </div>
    </div>
  );
};

export default DataReportPage;
