import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {apiSlice} from 'api/apiSlice';
import {tableEditorSlice} from 'store/tableEditorStore';

export const setupStore = (preloadedState) => {
  const store = configureStore({
    devTools: {
      stateSanitizer: (state) =>
        state.data ? {...state, data: '<<LONG_BLOB>>'} : state,
    },
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      tableEditor: tableEditorSlice.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(apiSlice.middleware),
  });

  setupListeners(store.dispatch);

  return store;
};

export const store = setupStore({});
