import {CloseCircleOutlined, RightOutlined} from '@ant-design/icons';
import {Alert, Popover, Tooltip} from 'antd';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import {validTypesForFilter} from 'consts';
import React, {useState} from 'react';

const FilterForm = ({columns, filter, setFilter}) => {
  return (
    <div className="flex-column" style={{alignItems: 'stretch'}}>
      {filter.errors?.length > 0 && (
        <Alert
          message={
            <div>
              {filter.errors?.map((error) => (
                <div key={error}>* {error}</div>
              ))}
            </div>
          }
          type="warning"
          style={{margin: '10px', fontSize: '12px'}}
        />
      )}
      <SelectOptions
        options={columns.map((column) => ({
          value: column.column_name,
          label: `${column.column_name} (${column.data_type})`,
        }))}
        selectedOption={filter.source_column}
        changeSelection={(value) => setFilter(value, 'source_column')}
        label="Source Column"
        required={true}
      />
      {filter.source_column && (
        <>
          <InputField
            label="Column Name"
            required={true}
            onChange={(e) => setFilter(e.target.value, 'alias')}
            value={filter.alias ?? filter.source_column}
          />
          <SelectOptions
            options={Object.keys(validTypesForFilter).map((option) => {
              return {
                value: option,
                label: option,
                disabled: !validTypesForFilter[option].includes(
                  filter.data_type
                ),
              };
            })}
            selectedOption={filter.filter_type}
            changeSelection={(value) => setFilter(value, 'filter_type')}
            label="Filter Type"
            required={true}
          />
          <InputField
            label="Description"
            onChange={(e) => setFilter(e.target.value, 'description')}
            value={filter.description}
          />
        </>
      )}
    </div>
  );
};

function Filter({columns, filter, setFilter, handleDelete, renderVisible}) {
  const [visible, setVisible] = useState(renderVisible);

  const onClick = () => {
    setVisible(false);
    handleDelete();
  };

  return (
    <Popover
      placement="right"
      open={visible}
      onOpenChange={(visible) => {
        if (!visible) {
          setFilter(false, 'renderVisible');
        }
        setVisible(visible);
      }}
      content={
        <FilterForm columns={columns} filter={filter} setFilter={setFilter} />
      }
      trigger="click"
      style={{
        width: '354px',
      }}
    >
      <div
        className="flex-row"
        style={{
          alignItems: 'center',
          padding: '10px',
          margin: '10px 0',
          border: '1px solid #d9d9d9',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title="Remove">
          <CloseCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          />
        </Tooltip>
        <span>
          {filter.alias ?? filter.source_column}
          {filter.errors?.length > 0 && (
            <span style={{color: 'red', marginLeft: '5px'}}>*</span>
          )}
        </span>
        <span style={{flex: 1}} />
        <RightOutlined />
      </div>
    </Popover>
  );
}

export default Filter;
