import {
  CalendarOutlined,
  NumberOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {parseDate, parseNumber} from 'Utils';
import {Button, Input, InputNumber, Popover} from 'antd';
import DatePicker from 'components/genericComponents/DatePicker';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import {integerOperators} from 'consts';
import React, {useEffect, useState} from 'react';

const FilterFields = ({filter, setFilter}) => {
  const [tempFilter, setTempFilter] = useState(filter);

  useEffect(() => {
    setTempFilter(filter);
  }, [filter]);

  const parseFilterValue = () => {
    const selectedKeysArray =
      typeof tempFilter.value === 'string'
        ? [tempFilter.value]
        : tempFilter.value;
    const split = selectedKeysArray?.[0]?.split(':');
    const selectedOperator = split?.[0] || null;
    const filterValue = split?.[1] || null;
    if (filter.filter_type === 'DATE') {
      return parseDate(filterValue, selectedOperator);
    } else {
      return parseNumber(filterValue, selectedOperator);
    }
  };

  return (
    <>
      {filter.filter_type === 'DATE' || filter.filter_type === 'INTEGER' ? (
        <Popover
          content={
            <OperatorFilter
              key={filter}
              selectedKeys={tempFilter.value}
              setSelectedKeys={(value) => setTempFilter({...tempFilter, value})}
              handleSearch={setFilter}
              confirm={() => setFilter(tempFilter)}
              col={{
                ...tempFilter,
                column_name: filter.alias,
              }}
            />
          }
          trigger="click"
          placement="bottom"
        >
          <Input
            value={parseFilterValue()}
            suffix={
              filter.filter_type === 'DATE' ? (
                <CalendarOutlined />
              ) : (
                <NumberOutlined />
              )
            }
          />
        </Popover>
      ) : null}
      {filter.filter_type === 'FREE_TEXT' ? (
        <div
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setFilter(tempFilter.value);
            }
          }}
          onBlur={() => setFilter(tempFilter.value)}
        >
          <InputField
            value={tempFilter.value}
            onChange={(e) =>
              setTempFilter({...tempFilter, value: e.target.value})
            }
            size="middle"
            icon={<SearchOutlined />}
          />
        </div>
      ) : null}
      {filter.filter_type === 'OPTIONS' ? (
        <SelectOptions
          options={filter.options}
          selectedOption={tempFilter.value}
          changeSelection={(value) => setFilter(value.join(','))}
          style={{width: '300px'}}
          multiple={true}
          size="middle"
        />
      ) : null}
    </>
  );
};

export default FilterFields;

export function OperatorFilter({
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  confirm,
  col,
}) {
  const selectedKeysArray =
    typeof selectedKeys === 'string' ? [selectedKeys] : selectedKeys;
  const split = selectedKeysArray?.[0]?.split(':');
  const selectedOperator = split?.[0] || null;
  const filterValue = split?.[1]
    ? split?.[1].includes(',')
      ? split?.[1].split(',')
      : split?.[1]
    : null;

  const handleFilterChange = (value, field) => {
    if (field === 'operator') {
      if (value !== 'BETWEEN') {
        setSelectedKeys([
          `${value}:${filterValue?.toString().split(',')?.[0] || ''}`,
        ]);
      } else {
        setSelectedKeys([`${value}:${filterValue}`]);
      }
    } else if (field === 'value') {
      setSelectedKeys([`${selectedOperator}:${value}`]);
    }
  };

  const canFilter = () => {
    if (selectedOperator === 'BETWEEN') {
      return filterValue[0] && filterValue[1];
    } else {
      return filterValue;
    }
  };

  const handleReset = () => {
    setSelectedKeys([]);
    handleSearch([], confirm, col.column_name);
  };

  return (
    <div>
      {col.filter_type === 'DATE' ? (
        <div>
          <SelectOptions
            options={integerOperators}
            selectedOption={selectedOperator}
            changeSelection={(value) => handleFilterChange(value, 'operator')}
            placeholder="Select an operator"
            style={{margin: 8, width: 200}}
            allowClear={false}
            size="middle"
          />
          <DatePicker
            range={selectedOperator === 'BETWEEN'}
            value={filterValue}
            setValue={(value) => handleFilterChange(value, 'value')}
            style={{margin: '0 8px', display: 'flex'}}
            key={selectedOperator + filterValue}
          />
        </div>
      ) : (
        <div>
          <SelectOptions
            options={integerOperators}
            selectedOption={selectedOperator}
            changeSelection={(value) => handleFilterChange(value, 'operator')}
            placeholder="Select an operator"
            style={{
              width: 200,
              margin: 8,
            }}
            allowClear={false}
            size="middle"
          />
          {selectedOperator === 'BETWEEN' ? (
            <div className="flex-row" style={{margin: '0 8px'}}>
              <InputNumber
                value={parseNumber(filterValue[0])}
                onChange={(value) =>
                  handleFilterChange([value, filterValue[1]], 'value')
                }
              />
              <InputNumber
                value={parseNumber(filterValue[1])}
                onChange={(value) =>
                  handleFilterChange([filterValue[0], value], 'value')
                }
              />
            </div>
          ) : (
            <InputNumber
              value={parseNumber(filterValue)}
              onChange={(value) => handleFilterChange(value, 'value')}
              style={{margin: '0 8px', display: 'block'}}
            />
          )}
        </div>
      )}
      <div
        className="flex-row"
        style={{
          padding: 8,
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleReset} size="small" disabled={!filterValue}>
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() =>
            canFilter && handleSearch(selectedKeys, confirm, col.column_name)
          }
          size="small"
          disabled={!canFilter()}
        >
          Filter
        </Button>
      </div>
    </div>
  );
}
