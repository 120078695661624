import BatchMapForm from 'components/BatchMapForm';
import PersonMapping from 'components/PersonMapping';
import React from 'react';

function PersonMappingPage() {
  return (
    <div>
      <h1>Person Mapping</h1>
      <PersonMapping />
      <BatchMapForm />
    </div>
  );
}

export default PersonMappingPage;
