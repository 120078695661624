import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'errorHandler';

const cmsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPages: builder.query({
      query: (version) => `/cms/page_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'CmsPages', id: arg}],
    }),
    fetchPage: builder.query({
      query: ({page_slug, version}) =>
        `/cms/page/${page_slug}/?version=${version}`,
      transformResponse: (response) => {
        const sortedWidgets =
          response.widgets?.sort((a, b) => a.position - b.position) || [];
        return {
          ...response,
          widgets: sortedWidgets,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'CmsPages', id: arg.page_slug + arg.version},
      ],
    }),
    fetchTags: builder.query({
      query: () => `/cms/tag/`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['CmsTags'],
    }),
    fetchCategories: builder.query({
      query: () => `/cms/category_tree`,
      transformErrorResponse: handleRTKQError,
      providesTags: ['CmsCategories'],
    }),
    savePage: builder.mutation({
      query: (page) => ({
        url: `/cms/page/`,
        method: 'POST',
        body: page,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, page) => [
        {type: 'CmsPages', id: page.slug + page.version},
        {type: 'CmsPages', id: page.slug + 'latest'},
        {type: 'CmsPages', id: 'latest'},
      ],
    }),
    updatePage: builder.mutation({
      query: (page) => ({
        url: `/cms/page/${page.slug}/`,
        method: 'PUT',
        body: page,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, page) => [
        {type: 'CmsPages', id: page.slug + page.version},
        {type: 'CmsPages', id: page.slug + 'latest'},
        {type: 'CmsPages', id: 'latest'},
      ],
    }),
    deletePage: builder.mutation({
      query: ({page_slug, version}) => ({
        url: `/cms/page/${page_slug}/?version=${version}`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: (result, error, arg) => [
        {type: 'CmsPages', id: arg.page_slug + arg.version},
        {type: 'CmsPages', id: arg.page_slug + 'latest'},
        {type: 'CmsPages', id: 'latest'},
        {type: 'CmsPages', id: 'published'},
      ],
    }),
    fetchSupersetToken: builder.query({
      query: (dashboardId) => `/cms/guest_token?dashboard_id=${dashboardId}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        'SupersetToken',
        {type: 'SupersetToken', id: arg},
      ],
      transformResponse: (response) => response.token,
    }),
    createOrUpdateTag: builder.mutation({
      query: (tag) => ({
        url: tag.id ? `/cms/tag/${tag.id}/` : `/cms/tag/`,
        method: tag.id ? 'PUT' : 'POST',
        body: tag,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages', 'CmsTags', 'CmsCategories'],
    }),
    deleteTag: builder.mutation({
      query: (tag_id) => ({
        url: `/cms/tag/${tag_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages', 'CmsTags', 'CmsCategories'],
    }),
    createOrUpdateCategory: builder.mutation({
      query: (category) => ({
        url: category.id ? `/cms/category/${category.id}/` : `/cms/category/`,
        method: category.id ? 'PUT' : 'POST',
        body: category,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages', 'CmsTags', 'CmsCategories'],
    }),
    deleteCategory: builder.mutation({
      query: (category_id) => ({
        url: `/cms/category/${category_id}/`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages', 'CmsTags', 'CmsCategories'],
    }),
  }),
});

export const {
  useFetchPagesQuery,
  useFetchPageQuery,
  useFetchTagsQuery,
  useFetchCategoriesQuery,
  useSavePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
  useFetchSupersetTokenQuery,
  useCreateOrUpdateTagMutation,
  useDeleteTagMutation,
  useCreateOrUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = cmsSlice;
