import Auth from 'Auth';
import {setMailtoMessage} from 'Utils';
import {Button, Layout, Menu} from 'antd';
import {HIDDEN_TABS, TRACK_LITE} from 'consts';
import React from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';

const {Header, Content, Footer} = Layout;

const TrackLayout = () => {
  const location = useLocation();
  const selectedKeys = location.pathname.split('/')?.[1];

  const permissions = Auth.getPermissions();

  // The menu items to display.
  const isLoggedInItems = [
    {
      key: 'home',
      label: <Link to="/">Home</Link>,
    },
    {
      key: 'audit',
      label: 'Audits',
      children: [
        {
          key: 'audits_overview',
          label: <Link to="/audits_overview">Audits Overview</Link>,
        },
        {
          key: 'audits_issue_overview',
          label: <Link to="/audits_issue_overview">Audits Issue Overview</Link>,
        },
        {
          key: 'audits_school_summary',
          label: <Link to="/audits_school_summary">Audits School Summary</Link>,
        },
        {
          key: 'audits',
          label: <Link to="/audits">Audits Table</Link>,
        },
        {
          key: 'audit_rules',
          label: <Link to="/audit_rules">Audit Rules</Link>,
        },
      ],
    },
    ...(Auth.permissions.access_to_esoy && !HIDDEN_TABS.includes('school_setup')
      ? [
          {
            key: 'school_setup',
            label: <Link to="/school_setup">E/SOY</Link>,
          },
        ]
      : []),
    ...(Auth.permissions.can_view_esoy_overview
      ? [
          {
            key: 'esoy_it_support',
            label: <Link to="/esoy_it_support">E/SOY (IT Support)</Link>,
          },
        ]
      : []),
    ...(Auth.permissions.access_to_staff_account_status &&
    !HIDDEN_TABS.includes('staff_roles')
      ? [
          {
            key: 'staff_roles',
            label: <Link to="/staff_roles">Staff Accounts</Link>,
          },
        ]
      : []),
    ...(Auth.permissions.access_to_staff_account_status
      ? [
          {
            key: 'staff_roles_it_support',
            label: (
              <Link to="/staff_roles_it_support">
                Staff Accounts (IT Support)
              </Link>
            ),
          },
        ]
      : []),
    ...(Auth.permissions.access_to_data_reports &&
    !HIDDEN_TABS.includes('data_report')
      ? [
          {
            key: 'data_report',
            label: <Link to="/data_report">Data Reports</Link>,
          },
        ]
      : []),
    ...(Auth.permissions.access_to_hs_schedule &&
    !HIDDEN_TABS.includes('hs_schedule')
      ? [
          {
            key: 'hs_schedule',
            label: <Link to="/hs_schedule">HS Scheduling</Link>,
          },
        ]
      : []),
    ...(Auth.permissions.access_to_source_manager &&
    !HIDDEN_TABS.includes('source_manager')
      ? [
          {
            key: 'source_manager',
            label: <Link to="/source_manager">Source Manager</Link>,
          },
        ]
      : []),
    ...(Auth.isLoggedIn()
      ? [
          {
            key: 'logout',
            label: <div onClick={() => Auth.logout()}>Logout</div>,
          },
          {
            key: 'user_details',
            label: Auth.getUser() && Auth.getUser().name,
            children: [
              {
                key: 'email',
                label: Auth.getUser()?.email,
                disabled: true,
              },
              {
                key: 'ps_site_code',
                label: (
                  <>
                    <strong>ps_site_code:</strong> {Auth.ps_site_code}
                  </>
                ),
                disabled: true,
              },
              ...(Object.entries(permissions)?.map((permission) => {
                return {
                  key: permission[0],
                  label: (
                    <>
                      <strong>{permission[0]}:</strong>{' '}
                      {permission[1]?.toString()}
                    </>
                  ),
                  disabled: true,
                };
              }) || []),
              {
                key: 'contact_it',
                label: (
                  <Button
                    type="primary"
                    onClick={() => {
                      window.open(
                        setMailtoMessage(
                          'itsupport@uncommonschools.org',
                          'TRACK Issue',
                          'Please describe the issue you are experiencing here.' +
                            '\n\n' +
                            'Please include any relevant information, such as the page you were on and the steps you took to get there.' +
                            '\n\n' +
                            'Thank you!' +
                            '\n\n' +
                            'User Details:' +
                            '\n' +
                            JSON.stringify(Auth.getUser(), null, 2)
                        )
                      );
                    }}
                  >
                    Contact IT Support
                  </Button>
                ),
              },
            ],
          },
        ]
      : []),
  ];

  const items = Auth.isLoggedIn()
    ? isLoggedInItems
    : [
        {
          key: 'home',
          label: <Link to="/">Home</Link>,
        },
        {
          key: 'login',
          label: <Link to="/login">Login</Link>,
        },
      ];

  const trackLiteItems = [
    {
      key: 'home',
      label: <Link to="/">Home</Link>,
    },
    ...(Auth.isLoggedIn()
      ? [
          {
            key: 'data_report',
            label: <Link to="/data_report">Data Reports</Link>,
          },
          {
            key: 'logout',
            label: <div onClick={() => Auth.logout()}>Logout</div>,
          },
          {
            key: 'user_details',
            label: Auth.getUser() && Auth.getUser().name,
            disabled: true,
          },
        ]
      : [
          {
            key: 'login',
            label: <Link to="/login">Login</Link>,
          },
        ]),
  ];

  return (
    <Layout className="track-layout">
      <Header className="header">
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['home']}
          selectedKeys={selectedKeys}
          items={TRACK_LITE ? trackLiteItems : items}
        />
      </Header>
      <Content style={{padding: '0 50px'}}>
        <div className="site-layout-content">
          <Outlet />
        </div>
      </Content>
      <Footer>
        <div style={{textAlign: 'center'}}>
          TRACK - Tracker Replacement Automated Correction Kit
        </div>
        <div style={{float: 'left', fontSize: '10px'}}>
          <a href="http://www.onlinewebfonts.com">&copy;oNline Web Fonts</a>
        </div>
      </Footer>
    </Layout>
  );
};

export default TrackLayout;
