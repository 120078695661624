import {ResponsiveLine} from '@nivo/line';
import {Spin, Table} from 'antd';
import {
  useFetchSchoolErrorRateSnapshotQuery,
  useFetchSchoolErrorRateWithStatusQuery,
} from 'api/auditsSlice';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

function DateErrorLineBar({data}) {
  return (
    <ResponsiveLine
      data={data}
      margin={{top: 50, right: 250, bottom: 80, left: 60}}
      xScale={{type: 'point'}}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="monotoneX"
      colors={{scheme: 'set3'}}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -35,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '# errors',
        legendOffset: -50,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{theme: 'background'}}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      tooltip={(p) => (
        <>
          <strong>{p.point.serieId}</strong>: {p.point.data.y}
        </>
      )}
      useMesh={true}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 150,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 120,
          itemHeight: 15,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

// Component reprsenting a comment.
const AuditsSchoolSummaryTable = ({siteId, siteAbbr}) => {
  const [graphHeight, setGraphHeight] = useState(0);

  const {
    data: schoolErrorRateWithStatus,
    isLoading: schoolErrorRateWithStatusIsLoading,
  } = useFetchSchoolErrorRateWithStatusQuery(siteId);

  const {
    data: schoolErrorRateSnapshot,
    isLoading: schoolErrorRateSnapshotIsLoading,
  } = useFetchSchoolErrorRateSnapshotQuery(siteId);

  useEffect(() => {
    if (schoolErrorRateSnapshot) {
      setGraphHeight(Math.max(400, schoolErrorRateSnapshot.length * 20));
    } else {
      setGraphHeight(0);
    }
  }, [schoolErrorRateSnapshot]);

  useEffect(() => {
    if (schoolErrorRateSnapshot) {
      setGraphHeight(Math.max(400, schoolErrorRateSnapshot.length * 20));
    } else {
      setGraphHeight(0);
    }
  }, [schoolErrorRateSnapshot]);

  const columns = [
    {
      title: 'Error Type',
      dataIndex: 'error_type',
      key: 'error_type',
      width: 300,
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 300,
      render: (text) => (
        <Link to={`/audits/${siteAbbr}?filter_field=${text}`}>{text}</Link>
      ),
    },
    {
      title: 'Total Errors',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      align: 'right',
    },
    {
      title: 'Active',
      dataIndex: 'status_active',
      key: 'status_active',
      width: 100,
      align: 'right',
    },
    {
      title: 'Fixed',
      dataIndex: 'status_fixed',
      key: 'status_fixed',
      width: 100,
      align: 'right',
    },
    {
      title: "Can't Fix",
      dataIndex: 'status_fix_later',
      key: 'status_fix_later',
      width: 100,
      align: 'right',
    },
    {
      title: '(Failed after fix)',
      dataIndex: 'failed_after_fix',
      key: 'failed_after_fix',
      width: 100,
      align: 'right',
    },
  ];

  return (
    <div>
      {/* The Graph */}
      <Spin spinning={schoolErrorRateSnapshotIsLoading}>
        <div
          style={{
            height: graphHeight,
          }}
        >
          {schoolErrorRateSnapshot && (
            <DateErrorLineBar data={schoolErrorRateSnapshot} />
          )}
        </div>
      </Spin>

      {/* The Table */}

      <Table
        dataSource={schoolErrorRateWithStatus}
        columns={columns}
        key={schoolErrorRateWithStatus}
        size="small"
        rowKey={(record) => record.field + record.error_type}
        loading={schoolErrorRateWithStatusIsLoading}
        scroll={{
          x: '100%',
          y: '80vh',
        }}
        pagination={false}
      />
    </div>
  );
};

export default AuditsSchoolSummaryTable;
