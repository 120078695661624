import {debounce} from 'lodash';
import React, {useEffect, useMemo, useRef} from 'react';

const useThrottle = (callback, delay = 1000) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const throttledCallback = useMemo(() => {
    const func = (...args) => {
      callbackRef.current(...args);
    };
    return debounce(func, delay, {leading: false, trailing: true});
  }, [delay]);

  return throttledCallback;
};

export default useThrottle;
