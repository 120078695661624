import {Spin} from 'antd';
import {useFetchSeedDataQuery} from 'api/seedsSlice';
import DiffCompare from 'components/seeds/DiffCompare';
import React, {useEffect, useState} from 'react';

const SeedDiff = ({seedId}) => {
  const [diffs, setDiffs] = useState([]);
  const [cols, setCols] = useState([]);

  const {data: dataSource, isLoading} = useFetchSeedDataQuery(seedId, {
    skip: !seedId,
  });

  const getDiffs = () => {
    const tempDiffs = [];
    const data = dataSource?.data;
    const draftData = dataSource?.draft_data;
    if (!data?.length) return [];
    const columns = Object.keys(data[0]);
    for (let i = 0; i < data.length; i++) {
      const newRow = {
        to: {},
      };
      const row = data?.[i];
      const draftRow = draftData?.[i];
      columns.forEach((col) => {
        if (draftRow && draftRow[col] !== row[col]) {
          newRow.to[col] = draftRow[col];
        }
      });
      if (Object.keys(newRow.to).length) {
        newRow.from = {...row};
        tempDiffs.push(newRow);
      }
    }
    setDiffs(tempDiffs);
    setCols(columns);
  };

  useEffect(() => {
    getDiffs();
  }, [dataSource]);

  return (
    <Spin spinning={isLoading}>
      <DiffCompare diffs={diffs} diffCols={cols} />
    </Spin>
  );
};

export default SeedDiff;
