import {EditOutlined, EyeOutlined, SearchOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {setContrast} from 'Utils';
import {Button, Divider, Spin, Tag, Tooltip, Tree} from 'antd';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useDeleteCategoryMutation,
  useDeleteTagMutation,
  useFetchCategoriesQuery,
  useFetchPagesQuery,
  useFetchTagsQuery,
} from 'api/cmsSlice';
import Input from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import TagsAndCategories from 'components/genericComponents/TagsAndCategories';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

const {DirectoryTree} = Tree;

const DataPortalOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const {data: pages, isLoading: pagesLoading} = useFetchPagesQuery(
    editorMode ? 'latest' : 'published'
  );
  const {data: tags, isLoading: tagsLoading} = useFetchTagsQuery();
  const {data: categories, isLoading: categoriesLoading} =
    useFetchCategoriesQuery();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createOrUpdateTag] = useCreateOrUpdateTagMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [deleteTag] = useDeleteTagMutation();

  const [defaultData, setDefaultData] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterTagOptions, setFilterTagOptions] = useState([]);

  useEffect(() => {
    const options = tags?.map((tag) => {
      return {
        ...tag,
        label: tag.name,
        value: tag.id,
        color: tag.color,
      };
    });
    options?.unshift({
      id: 'draft',
      name: 'Draft',
      label: 'Draft',
      value: 'draft',
      color: 'gold',
    });
    options?.unshift({
      id: 'unpublished',
      name: 'Unpublished Changes',
      label: 'Unpublished Changes',
      value: 'unpublished',
      color: 'blue',
    });
    setFilterTagOptions(options);
  }, [tags]);

  useEffect(() => {
    if (!filterTagOptions?.length) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const tagParams = params.getAll('tag');
    const newTags =
      tagParams
        .map((t) => filterTagOptions?.find((d) => d.name === t))
        .filter((t) => t) || [];
    setFilterTags(newTags);
  }, [location.search, filterTagOptions]);

  useEffect(() => {
    if (!pages) {
      return;
    }
    const loop = (data) => {
      const looped = data?.map((item) => {
        if (item.type === 'node') {
          return {
            ...item,
            name: item.title,
            title: <span style={{fontSize: 18}}>{item.title}</span>,
            key: item.type + item.id,
            children: loop(item.children),
          };
        } else {
          return {
            ...item,
            name: item.title,
            title: (
              <>
                <Link
                  to={`/data_portal/${item.slug}/${editorMode ? 'editor' : ''}`}
                  style={{fontSize: 16}}
                >
                  {item.title}
                </Link>
                {!item.published && (
                  <Tag
                    color="gold"
                    style={{marginRight: 3, marginLeft: 5}}
                    onClick={() => {
                      onChangeFilterTags(['draft']);
                    }}
                    key="draft"
                  >
                    Draft
                  </Tag>
                )}
                {editorMode &&
                  item.published &&
                  item.published_version !== item.version && (
                    <Tag
                      color="blue"
                      style={{marginRight: 3, marginLeft: 5}}
                      onClick={() => {
                        onChangeFilterTags(['unpublished']);
                      }}
                      key="unpublished"
                    >
                      Unpublished Changes
                    </Tag>
                  )}
                {item.tags?.map((tag) => {
                  tag = tags?.find((t) => t.id === tag);
                  if (!tag) {
                    return null;
                  }
                  const textColor = setContrast(tag.color);
                  return (
                    <Tag
                      color={tag.color}
                      style={{
                        marginRight: 1,
                        marginLeft: 5,
                        color: textColor,
                        borderColor:
                          textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                      }}
                      onClick={() => onChangeFilterTags([tag.id])}
                      key={tag.name}
                    >
                      {tag.name}
                    </Tag>
                  );
                })}
              </>
            ),
            key: item.type + item.id,
            isLeaf: true,
          };
        }
      });
      return looped;
    };
    const newData = loop(pages.children);
    setDefaultData(newData || []);
  }, [pages, tags, editorMode]);

  const dataList = useMemo(() => {
    // recursively filter defaultData
    // only return nodes that have children or match the search value
    // if a node name matches the search value, return the node and all its children
    const filterData = (data = []) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
          filtered.push(item);
        } else if (item.children) {
          const children = filterData(item.children);
          if (children.length) {
            filtered.push({
              ...item,
              children,
            });
          }
        }
      });
      return filtered;
    };
    const filteredData = searchValue ? filterData(defaultData) : defaultData;

    // recursively filter filteredData based on filterTags
    // only return nodes that have children
    // only return leaf nodes that have all the tags in filterTags
    // if the tag is 'draft' or 'unpublished', check if the page is a draft or has unpublished changes
    const filterTagsData = (data = []) => {
      const filtered = [];
      data.forEach((item) => {
        if (item.tags) {
          const hasTag = filterTags.every((t) => {
            if (t.id === 'draft') {
              return !item.published;
            }
            if (t.id === 'unpublished') {
              return item.published && item.published_version !== item.version;
            }
            return item.tags.findIndex((s) => s === t.id) !== -1;
          });
          if (hasTag) {
            filtered.push(item);
          }
        } else if (item.children) {
          const children = filterTagsData(item.children);
          if (children.length) {
            filtered.push({
              ...item,
              children,
            });
          }
        }
      });
      return filtered;
    };
    const filteredTagsData = filterTags
      ? filterTagsData(filteredData)
      : filteredData;

    return filteredTagsData;
  }, [defaultData, searchValue, filterTags]);

  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const onChangeFilterTags = (value) => {
    const search = new URLSearchParams(location.search);
    // delete all tag params
    search.delete('tag');
    value.forEach((t) => {
      const tag = filterTagOptions?.find((d) => d.id === t);
      search.append('tag', tag?.name);
    });
    navigate(`${location.pathname}?${search.toString()}`);
  };

  return (
    <div>
      <h1>Data Portal Overview</h1>
      <div className="flex-row" style={{margin: '20px'}}>
        <Input
          allowClear
          label="Search"
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined />}
          size="medium"
          style={{width: '450px'}}
          value={searchValue}
        />
        <SelectOptions
          options={filterTagOptions}
          label="Filter by Tag"
          required={false}
          size="medium"
          selectedOption={filterTags.map((t) => t.id)}
          changeSelection={(value) => onChangeFilterTags(value)}
          mode="tags"
          allowClear={true}
          multiple={true}
          style={{width: '450px'}}
        />
      </div>
      <Button
        type="primary"
        href="/data_portal/new/editor"
        style={{margin: 20}}
      >
        Create New Page
      </Button>
      <Spin spinning={pagesLoading || tagsLoading || categoriesLoading}>
        {dataList.length ? (
          <DirectoryTree
            autoExpandParent={true}
            selectable={false}
            treeData={dataList}
          />
        ) : null}
        {dataList.length === 0 && !pagesLoading && <p>No pages found.</p>}
      </Spin>
      {editorMode && (
        <div>
          <Divider />
          <TagsAndCategories
            categories={categories}
            createOrUpdateCategory={createOrUpdateCategory}
            createOrUpdateTag={createOrUpdateTag}
            deleteCategory={deleteCategory}
            deleteTag={deleteTag}
            tags={tags}
          />
        </div>
      )}
      {Auth.permissions.access_to_data_portal_editor ? (
        <Tooltip
          title={editorMode ? 'Preview mode' : 'Editor mode'}
          placement="right"
        >
          <Button
            onClick={() =>
              navigate(`/data_portal${editorMode ? '' : '/editor'}`)
            }
            type="primary"
            style={{
              top: '15%',
              left: 0,
              height: '50px',
              position: 'fixed',
              zIndex: 1001,
              fontSize: '1.8em',
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default DataPortalOverviewPage;
