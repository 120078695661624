import {useFetchPipelineQuery} from 'api/apiSlice';
import React from 'react';

const LastRefresh = () => {
  const {data} = useFetchPipelineQuery();

  return (
    <div
      style={{
        marginBottom: '10px',
      }}
    >
      Last Refresh: {data}
    </div>
  );
};

export default LastRefresh;
