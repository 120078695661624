import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <h1>404</h1>
      <p>The page you were searching for was not found.</p>
    </div>
  );
};

export default NotFoundPage;
