import {UserOutlined} from '@ant-design/icons';
import {Button, Input, Table} from 'antd';
import {useLazyLookupUcpidQuery} from 'api/apiSlice';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const UCPersonLookup = () => {
  const [lookupUcpid, {data, isLoading}] = useLazyLookupUcpidQuery();

  const [detailsToLookup, setDetailsToLookup] = useState('');

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 80,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 80,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 80,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 80,
    },
    {
      title: 'UCPID',
      dataIndex: 'ucpid',
      key: 'ucpid',
      width: 120,
      render: (ucpid, record) => (
        <>
          <Link to={'/person/' + ucpid}>{ucpid}</Link>
        </>
      ),
    },
  ];

  return (
    <div>
      <h2>Enter details to lookup</h2>
      <Input
        placeholder="Details to look up..."
        prefix={<UserOutlined />}
        value={detailsToLookup}
        onChange={(e) => setDetailsToLookup(e.target.value)}
        onPressEnter={() => lookupUcpid(detailsToLookup)}
        style={{width: '50%', marginRight: 8, marginBottom: 16}}
      />
      <Button loading={isLoading} onClick={() => lookupUcpid(detailsToLookup)}>
        Load People
      </Button>
      {data && (
        <Table
          pagination={{
            total: data?.length,
            pageSize: 50,
            showSizeChanger: false,
            position: ['bottomRight', 'topRight'],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={data}
          columns={columns}
          key={detailsToLookup}
          size="small"
          loading={isLoading}
          bordered
          scroll={{y: '80vh'}}
          rowKey="ucpid" // Todo: this needs to be provided.
        />
      )}
    </div>
  );
};

export default UCPersonLookup;
