import {embedDashboard} from '@superset-ui/embedded-sdk';
import {useFetchSupersetTokenQuery} from 'api/cmsSlice';
import {SUPERSET_URL} from 'consts';
import React, {useEffect} from 'react';

const SupersetChartDisplay = ({source, dashboardId}) => {
  const {data: guestToken, refetch} = useFetchSupersetTokenQuery(dashboardId, {
    skip: !dashboardId,
  });

  useEffect(() => {
    if (!source || !dashboardId || !guestToken) return;
    const embed = async () => {
      try {
        await embedDashboard({
          id: source, // given by the Superset embedding UI
          supersetDomain: SUPERSET_URL,
          mountPoint: document.getElementById('dashboard'), // html element in which iframe render
          fetchGuestToken: () => guestToken,
          dashboardUiConfig: {
            hideTitle: true,
            filters: {},
            hideChartControls: true,
            hideTab: true,
          },
        });
      } catch (err) {
        console.error(err);
      }
    };
    if (document.getElementById('dashboard')) {
      embed();
      const container = document.getElementById('dashboard');
      if (container && container.children[0]) {
        container.style.width = '100%';
        container.children[0].style.height = '800px';
        container.children[0].style.width = '100%';
        container.children[0].style.border = 'none';
      }
    }
  }, [source, dashboardId, guestToken]);

  // refresh token every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 280000);
    return () => clearInterval(interval);
  }, [dashboardId]);

  return (
    <>
      {source && dashboardId && guestToken && (
        <div
          key={guestToken}
          id="dashboard"
          style={{
            width: '100%',
            height: 'minContent',
            border: '1px solid #f0f0f0',
            borderRadius: '4px',
          }}
        />
      )}
    </>
  );
};

export default SupersetChartDisplay;
