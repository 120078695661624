import Auth from 'Auth';
import {Button, Card, Form, Input} from 'antd';
import {useGet2faQuery, useVerify2faMutation} from 'api/authSlice';
import QRCode from 'qrcode.react';
import React from 'react';

const TwoFactorAuth = () => {
  const [form] = Form.useForm();

  const {data: setup2faData} = useGet2faQuery();
  const [verify2fa] = useVerify2faMutation();

  const verifyTwoFactor = (e) => {
    verify2fa({
      otp: e.verificationCode,
    })
      .unwrap()
      .then((res) => {
        Auth.otpLogin(res);
        window.location.href = '/data_report';
      });
  };

  return (
    <Card
      title="Two-Factor Authentication"
      style={{width: '500px', margin: 'auto'}}
    >
      {setup2faData?.otpauth_url && (
        <div>
          <p>Scan this QR code with your authenticator app:</p>
          <QRCode value={setup2faData.otpauth_url} />
          <p>Or enter this secret key manually: {setup2faData.secret_key}</p>
        </div>
      )}
      <Form form={form} name="control-hooks" onFinish={verifyTwoFactor}>
        <Form.Item
          label="Verification Code"
          name="verificationCode"
          rules={[
            {
              required: true,
              message: 'Please enter a verification code.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{textAlign: 'center'}}>
          <Button type="primary" htmlType="submit">
            Verify
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TwoFactorAuth;
