import {LoadingOutlined} from '@ant-design/icons';
import {Button, Card, Col, Form, Row, Table, message} from 'antd';
import {useBatchMapUcpidsMutation} from 'api/apiSlice';
import ImportFile from 'components/genericComponents/ImportFile';
import React, {useEffect, useState} from 'react';

function BatchMapForm() {
  const [data, setData] = useState([]);
  const [incorrectData, setIncorrectData] = useState([]);
  const [validatedData, setValidatedData] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [title, setTitle] = useState('Preview');

  const [batchMapUcpids, {isLoading}] = useBatchMapUcpidsMutation();

  useEffect(() => {
    if (data.length) {
      if (!data[0].ucpid_to_merge || !data[0].primary_ucpid) {
        message.error({
          content:
            'CSV file must contain columns "ucpid_to_merge" and "primary_ucpid"',
          duration: 10,
        });
        return false;
      } else {
        const tempIncorrectData = [];
        const tempData = [];
        for (const newData of data) {
          if (newData.ucpid_to_merge && newData.primary_ucpid) {
            tempData.push(newData);
          } else {
            tempIncorrectData.push({...newData, error: 'Missing data'});
          }
        }
        setValidatedData(tempData);
        setIncorrectData(tempIncorrectData);
        if (tempData.length) {
          setSubmitEnabled(true);
        } else {
          message.error({
            content: 'No data found in CSV file',
            duration: 10,
          });
        }
        setTitle('Preview');
      }
    } else {
      setSubmitEnabled(false);
    }
  }, [data]);

  const submitForm = (e) => {
    e.preventDefault();
    batchMapUcpids(validatedData)
      .unwrap()
      .then((response) => {
        const tempIncorrectData = [...incorrectData];
        const tempData = [];
        for (const newData of response) {
          if (newData.error) {
            tempIncorrectData.push(newData);
          } else {
            tempData.push(newData);
          }
        }
        setIncorrectData(tempIncorrectData);
        setValidatedData(tempData);
      })
      .finally(() => {
        // Reset the file input
        setFiles([]);
        setTitle('Results');
        setSubmitEnabled(false);
      });
  };

  return (
    <Card style={{margin: '20px 0'}}>
      <Form>
        <h3>Merge multiple UCPIDs</h3>
        <p>
          Select a CSV file with two columns of data: '
          <strong>ucpid_to_merge</strong>' and '<strong>primary_ucpid</strong>'
        </p>
        <Row gutter={20}>
          <Col>
            <ImportFile
              type="file"
              accept=".csv"
              fileList={files}
              setData={setData}
              setFiles={setFiles}
            />
          </Col>
          <Col>
            <Button
              disabled={!submitEnabled}
              target="file"
              onClick={submitForm}
            >
              {isLoading ? <LoadingOutlined /> : 'Submit'}
            </Button>
          </Col>
        </Row>
        <br />
        {incorrectData.length > 0 && (
          <>
            <h4>Incorrect Data:</h4>
            <Table
              scroll={{x: '100%'}}
              style={{marginTop: '20px'}}
              dataSource={incorrectData.map((row, index) => {
                return {...row, key: index};
              })}
              pagination={false}
              columns={[
                {
                  title: 'ucpid_to_merge',
                  key: 'ucpid_to_merge',
                  dataIndex: 'ucpid_to_merge',
                },
                {
                  title: '',
                  key: 'arrow',
                  dataIndex: 'arrow',
                  render: () => <span>&#8594;</span>,
                },
                {
                  title: 'primary_ucpid',
                  key: 'primary_ucpid',
                  dataIndex: 'primary_ucpid',
                },
                {
                  title: 'error',
                  key: 'error',
                  dataIndex: 'error',
                },
              ]}
            />
          </>
        )}
        {validatedData.length > 0 && (
          <>
            <h4>{title}:</h4>
            <Table
              scroll={{x: '100%'}}
              style={{marginTop: '20px'}}
              dataSource={validatedData.map((row, index) => {
                return {...row, key: index};
              })}
              pagination={false}
              columns={[
                {
                  title: 'ucpid_to_merge',
                  key: 'ucpid_to_merge',
                  dataIndex: 'ucpid_to_merge',
                },
                {
                  title: '',
                  key: 'arrow',
                  dataIndex: 'arrow',
                  render: () => <span>&#8594;</span>,
                },
                {
                  title: 'primary_ucpid',
                  key: 'primary_ucpid',
                  dataIndex: 'primary_ucpid',
                },
              ]}
            />
          </>
        )}
      </Form>
    </Card>
  );
}

export default BatchMapForm;
