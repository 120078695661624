export const diffs = [
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1016391227',
      last_name: '1',
      first_name: '1',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2021-07-01',
      exitdate: '2022-07-01',
      exit_code_full: 'Graduated',
      enrollmentcount: 1,
      yearsinuncommon: 5,
    },
    to: null,
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1009204931',
      last_name: '1',
      first_name: '1',
      grade_level: '5th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'P',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2023-09-01',
      exitdate: '2024-06-15',
      exit_code_full: '?',
      enrollmentcount: 2,
      yearsinuncommon: 1,
    },
    to: null,
  },
  {
    from: {
      region: '',
      school: 'RPLS',
      student_number: '123456789',
      last_name: '',
      first_name: '',
      grade_level: '8th ',
      enroll_status: 'Enrolled',
      gender: 'F',
      race: '',
      ethnicityhispanic: 'Yes',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2019-07-15',
      exitdate: '2020-06-30',
      exit_code_full: 'Graduated',
      enrollmentcount: 1,
      yearsinuncommon: 7,
    },
    to: {
      region: 'Boston',
      student_number: '123456789',
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1090444336623',
      last_name: '2',
      first_name: '2',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'F',
      race: '',
      ethnicityhispanic: 'Yes',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2019-07-15',
      exitdate: '2020-06-30',
      exit_code_full: 'Graduated',
      enrollmentcount: 1,
      yearsinuncommon: 7,
    },
    to: {
      yearsinuncommon: 8,
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1084577433',
      last_name: '3',
      first_name: '3',
      grade_level: '7th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2023-08-01',
      exitdate: '2024-06-15',
      exit_code_full: '?',
      enrollmentcount: 1,
      yearsinuncommon: 4,
    },
    to: {
      school: 'BSC',
      last_name: '',
    },
  },
  {
    from: null,
    to: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1060392328',
      last_name: '4',
      first_name: '4',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'P',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2023-08-01',
      exitdate: '2024-06-15',
      exit_code_full: '?',
      enrollmentcount: 1,
      yearsinuncommon: 5,
    },
  },
  {
    from: null,
    to: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1024081525',
      last_name: '5',
      first_name: '5',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'F',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2023-08-01',
      exitdate: '2024-06-15',
      exit_code_full: '?',
      enrollmentcount: 1,
      yearsinuncommon: 2,
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1033022632',
      last_name: '6',
      first_name: '6',
      grade_level: '6th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2023-08-01',
      exitdate: '2024-06-15',
      exit_code_full: '?',
      enrollmentcount: 1,
      yearsinuncommon: 3,
    },
    to: {
      school: 'BSC',
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1004137727',
      last_name: '7',
      first_name: '7',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2020-08-24',
      exitdate: '2020-08-24',
      exit_code_full: 'Never Showed Up for Class',
      enrollmentcount: 1,
      yearsinuncommon: 4,
    },
    to: {
      enroll_status: 'Expelled',
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1029228221',
      last_name: '8',
      first_name: '8',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'FDC',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2020-08-24',
      exitdate: '2020-08-24',
      exit_code_full: 'Never Showed Up for Class',
      enrollmentcount: 1,
      yearsinuncommon: 4,
    },
    to: {
      last_name: '8-Old',
      grade_level: '9th Grade',
    },
  },
  {
    from: {
      region: 'Boston',
      school: 'RPLS',
      student_number: '1020633636',
      last_name: '9',
      first_name: '9',
      grade_level: '8th Grade',
      enroll_status: 'Enrolled',
      gender: 'M',
      race: '',
      ethnicityhispanic: 'No',
      lunchstatus: 'P',
      iep_flag: '',
      ell_flag: '',
      entrydate: '2020-08-28',
      exitdate: '2021-06-27',
      exit_code_full: 'Graduated',
      enrollmentcount: 1,
      yearsinuncommon: 8,
    },
    to: {
      ethnicityhispanic: 'Yes',
      lunchstatus: 'A',
    },
  },
];

export const diffCols = [
  'region',
  'school',
  'student_number',
  'last_name',
  'first_name',
  'grade_level',
  'enroll_status',
  'gender',
  'race',
  'ethnicityhispanic',
  'lunchstatus',
  'iep_flag',
  'ell_flag',
  'entrydate',
  'exitdate',
  'exit_code_full',
  'enrollmentcount',
  'yearsinuncommon',
];
