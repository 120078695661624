import Chat from 'components/search/ChatBot';
import React from 'react';

const SearchPage = () => {
  return (
    <div>
      <h1>Search Bot</h1>
      <Chat style={{height: '80vh'}} />
    </div>
  );
};

export default SearchPage;
