export const TRACK_API_BASE_URL = process.env.REACT_APP_TRACK_API_BASE_URL;
export const TRACK_BASE_URL = process.env.REACT_APP_TRACK_BASE_URL;

export const LOGIN_URL = `${TRACK_API_BASE_URL}/auth/url?redirect_uri=${TRACK_BASE_URL}/login_done/`;
export const LOGIN_DONE_URL = `${TRACK_API_BASE_URL}/auth/`;

export const ROLLOVER_MODE = process.env.REACT_APP_ROLLOVER_MODE;

export const SUPERSET_URL = process.env.REACT_APP_SUPERSET_URL;
export const SUPERSET_PASSWORD = process.env.REACT_APP_SUPERSET_PASSWORD;
export const SUPERSET_USERNAME = process.env.REACT_APP_SUPERSET_USERNAME;

export const TRACK_LITE = process.env.REACT_APP_TRACK_LITE;

export const HIDDEN_TABS = process.env.REACT_APP_HIDDEN_TABS || [];

export const LOCALE = process.env.REACT_APP_LOCALE || 'en-US';

// List of fix statuses and translation from server.
export const FIX_STATUS_DISPLAY = {
  active: 'Active',
  fixed: 'Fixed',
  fix_later: "Can't Fix",
  failed_after_fix: 'Failed after fix',
};

export const REVIEW_STATUS_DISPLAY = {
  review_promote: 'Promote',
  review_retain: 'Retain',
  review_exit: 'Exit',
  review_transfer: 'Transfer',
  NA: 'N/A (Data problem)',
};

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const GRADES = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const CROSS_GRADE = 100;

export const GRADESPANS = ['All', 'ES', 'MS', 'HS'];

export const YEAR = 2023;

export const DRAFT_DUE_DATE = 'Wednesday, June 14, 2023';

export const FINAL_DUE_DATE = 'Friday, August 4, 2023';

export const aggregationOptions = [
  'COUNT',
  'DISTINCT',
  'SUM',
  'AVG',
  'MIN',
  'MAX',
];

export const filterOptions = ['DATE', 'INTEGER', 'FREE_TEXT', 'OPTIONS'];

export const stringOperators = [
  'EQUALS',
  'NOT_EQUALS',
  'CONTAINS',
  'NOT_CONTAINS',
  'STARTS_WITH',
  'ENDS_WITH',
  'IS_NULL',
  'IS_NOT_NULL',
];

export const integerOperators = [
  'EQUALS',
  'NOT_EQUALS',
  'GREATER_THAN',
  'GREATER_THAN_OR_EQUAL',
  'LESS_THAN',
  'LESS_THAN_OR_EQUAL',
  'BETWEEN',
];

export const optionsOperators = ['IN', 'NOT_IN', 'IS_NULL', 'IS_NOT_NULL'];

export const validTypesForFilter = {
  DATE: ['DATE'],
  FREE_TEXT: ['STRING', 'INTEGER', 'DATE'],
  INTEGER: ['INTEGER'],
  OPTIONS: ['STRING', 'INTEGER', 'DATE', 'BOOLEAN'],
};

export const validMetricsForColumnType = {
  COUNT: ['INTEGER', 'DATE', 'BOOLEAN', 'STRING'],
  DISTINCT: ['INTEGER', 'DATE', 'BOOLEAN', 'STRING'],
  SUM: ['INTEGER'],
  AVG: ['INTEGER'],
  MIN: ['INTEGER', 'DATE'],
  MAX: ['INTEGER', 'DATE'],
};

export const statuses = {
  removed: {
    label: 'Removed',
    color: '#f7a6a6',
    icon: 'disabled_by_default',
  },
  duplicate: {
    label: 'Duplicates',
    color: '#faad14',
    icon: 'copy_all',
  },
  edited: {
    label: 'Edited',
    color: '#1890ff',
    icon: 'edit',
  },
  invalid: {
    label: 'Invalid Values',
    color: '#f5222d',
    icon: 'warning',
  },
  blank: {
    label: 'Missing Values',
    color: 'rgba(0, 0, 0, 0.45)',
    icon: 'unknown_med',
  },
  newRow: {
    label: 'New Rows',
    color: '#52c41a',
    icon: 'add_box',
  },
  // - removed: the row is empty
  // - duplicate: the primary key is not unique OR the row has a non-unique value in a unique column (list of duplicate columns or sets of columns)
  // - edited: the row has been edited
  // - invalid: the row has invalid cells (list of invalid cells)
  // - empty: the row has empty cells (list of empty cells)
  // - newRow: the row is new
};
