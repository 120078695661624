import UCPersonDetails from 'components/UCPersonDetails';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const UCPersonDetailsPage = () => {
  const params = useParams();
  const [ucpid, setUcpid] = useState(null);

  useEffect(() => {
    const ucpid = params.ucpid;
    setUcpid(ucpid);
  }, []);

  return (
    <>
      <h1>Uncommon Person Details</h1>
      <UCPersonDetails ucpid={ucpid} />
    </>
  );
};

export default UCPersonDetailsPage;
