import {DatePicker} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

const {RangePicker} = DatePicker;
const DatePickerComponent = ({value, setValue, range, style}) => {
  const format = 'YYYY-MM-DD';
  const [date, setDate] = useState(null);

  useEffect(() => {
    let newDate;
    if (!value || value === 'null') {
      newDate = null;
    } else if (range) {
      if (typeof value === 'string') {
        newDate = value?.split(',')?.map((v) => moment(v));
      } else {
        newDate = value?.map((v) => moment(v));
      }
      if (newDate?.some((d) => !d?._isValid)) {
        newDate = null;
      }
    } else {
      if (!value?._isValid) {
        newDate = null;
      }
      newDate = moment(value, format);
    }
    setDate(newDate);
  }, [value]);

  const handleChange = (newValue) => {
    let newDate;
    if (!newValue) {
      setValue(undefined);
      return;
    }
    if (range) {
      newDate = newValue?.map((v) => moment(v, format));
      setValue(newValue.map((v) => v.format(format)));
    } else {
      newDate = moment(newValue, format);
      setValue(newValue.format(format));
    }
    if (!newDate?._isValid) {
      newDate = null;
    }
    setDate(newDate);
  };

  if (!range) {
    return (
      <DatePicker value={date ?? null} onChange={handleChange} style={style} />
    );
  } else {
    return (
      <RangePicker
        ranges={{
          Today: [moment(), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        value={date ?? []}
        onChange={handleChange}
        style={style}
      />
    );
  }
};

export default DatePickerComponent;
